import Api from "@/apis/Api";

export default {
  state: {
    permission_paginate: [],
    scrollPaginate: [],
    all_permission: [],
    meta: {},
  },
  getters: {
    getters_permission_meta: (state) => state.meta,
    getters_permission_paginate: (state) => state.permission_paginate,
    getters_permission: (state) => state.all_permission,
    getters_scroll_paginate: (state) => state.scrollPaginate,
  },
  mutations: {
    SET_PAGINATE_PERMISSION: (state, permission) =>
      (state.scrollPaginate = permission),
    SET_ALL_PERMISSION: (state, permission) =>
      (state.all_permission = permission),
    SET_SAVEPERMISSION: (state, permissionBasket) =>
      (state.permission_paginate = permissionBasket),
    SET_PERMISSION_META: (state, meta) => (state.meta = meta),
    REMOVE_PERMISSION: (state, permission_id) => {
      state.permission_paginate.filter((permission, index) => {
        permission.id === permission_id
          ? state.permission_paginate.splice(index, 1)
          : null;
      });
    },
  },
  actions: {
    async getPermission({ commit }, page_number) {
      page_number = page_number || 1;
      const response = await Api()
        .get("/permission_paginate?page=" + page_number)
        .then((res) => res.data);
      commit("SET_PAGINATE_PERMISSION", response.data);
      commit("SET_PERMISSION_META", response.meta);
    },

    async getAllPermission({ commit }) {
      const response = await Api()
        .get("/all_permission")
        .then((res) => res.data);
      commit("SET_ALL_PERMISSION", response.data);
    },

    async savePermission({ commit }, permissionBasket) {
      await Api()
        .post("/savepermission", { permissionBasket })
        .then((res) => commit("SET_SAVEPERMISSION", res.data.data));
    },

    async removePermission({ commit }, permission_id) {
      await Api()
        .delete(`/removepermission/${permission_id}`)
        .then(() => commit("REMOVE_PERMISSION", permission_id));
    },
  },
};
