import Api from "@/apis/Api";

export default {
  state: {
    session: [],
  },
  getters: {
    getters_session: (state) => state.session,
  },
  mutations: {
    SET_SESSION: (state, session) => (state.session = session),
    SET_SAVESESSION: (state, sessionBasket) =>
      state.session.push(sessionBasket),
    REMOVE_SESSION: (state, session_id) => {
      state.session.filter((session, index) => {
        session.id === session_id ? state.session.splice(index, 1) : null;
      });
    },
  },
  actions: {
    async getSession({ commit }) {
      const response = await Api()
        .get("/session")
        .then((res) => res.data);
      commit("SET_SESSION", response.data);
    },
    async saveSession({ commit }, sessionBasket) {
      await Api()
        .post("/savesession", { sessionBasket })
        .then((res) => commit("SET_SAVESESSION", res.data));
    },
    async removeSession({ commit }, session_id) {
      await Api()
        .delete(`/removesession/${session_id}`)
        .then(() => commit("REMOVE_SESSION", session_id));
    },
  },
};
