/** @format */

import Api from "@/apis/Api";

export default {
	state: {
		authenticated: false,
		user: null,
		abilities: [],
		authorized: false,
	},
	getters: {
		authenticated: (state) => state.authenticated,
		user: (state) => state.user,
		password: (state) => state.user,
		getters_abilities: (state) => state.abilities,
		getters_authorized: (state) => state.authorized,
	},
	mutations: {
		SET_AUTHENTICATED: (state, value) => (state.authenticated = value),
		SET_AUTHORIZED: (state, value) => (state.authorized = value),
		SET_USER: (state, value) => (state.user = value),
		MUTATION_PASSWORD: (state, user) => (state.user = user),
		SET_ABILITIES: (state, data) => (state.abilities = data),
	},
	actions: {
		async signIn({ dispatch }, credentials) {
			await Api()
				.post("/login", credentials)
				.then((response) => {
					localStorage.setItem("token", response.data);
				});
			return dispatch("me");
		},

		async signOut({ dispatch }) {
			await Api()
				.post("/logout")
				.then(() => localStorage.removeItem("token"));
			return dispatch("me");
		},

		me({ commit }) {
			return Api()
				.get("/user")
				.then((response) => {
					commit("SET_AUTHENTICATED", true);
					commit("SET_USER", response.data);
					commit("SET_AUTHORIZED", true);
				})
				.catch(() => {
					commit("SET_AUTHENTICATED", false);
					commit("SET_USER", null);
					commit("SET_AUTHORIZED", false);
				});
		},

		async getUserRolePermission({ commit }) {
			await Api()
				.get("permission")
				.then((res) => commit("SET_ABILITIES", res.data.data));
		},
	},
};
