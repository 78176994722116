import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader

const vuetify = new Vuetify({
	theme: {
		themes: {
			light: {
				primary: "#002e69",
				secondary: "#ffc601",
			},
		},
	},
});
Vue.use(Vuetify);

const opts = {
	icons: {
		iconfont: "mdi",
	},
};

export default new Vuetify(opts, vuetify);
