/** @format */

import Api from "@/apis/Api";

export default {
	state: {
		level_course: [],
		student_level_course: [],
		transcript: [],
	},
	getters: {
		getters_level_course: (state) => state.student_level_course,
		getters_transcript: (state) => state.transcript,
	},
	mutations: {
		SET_EMPTY_COURSE: (state, empty) => (state.empty = empty),
		SET_LEVEL_COURSE: (state, level_course) => {
			if (level_course === undefined) {
				state.student_level_course = 0;
				return;
			}
			state.level_course = level_course.reduce(function (acc, obj) {
				let key = "LEVEL" + obj["level"].level;

				if (!acc[key]) {
					acc[key] = [];
				}
				acc[key].push({
					semester: obj.semester,
					courses: obj.header_campus_course_session.header_campus_course,
					mid_sem: obj.mid_sem_score,
					end_of_sem: obj.end_of_sem_score,
					total: obj.total_score,
					grade: obj.grade,
					resit: obj.resit,
					registered: obj.registered,
					passed: obj.passed,
					published: obj.header_campus_course_session.is_published,
				});

				return acc;
			}, {});

			let levelBasket = [];
			for (const [key, value] of Object.entries(state.level_course)) {
				let item = value.reduce(function (acc, obj) {
					let prop = obj["semester"].semester;

					if (!acc[prop]) {
						acc[prop] = [];
					}

					acc[prop].push({
						course_scores: {
							mid_sem: obj.mid_sem,
							end_of_sem: obj.end_of_sem,
							total: obj.total,
							grade: obj.grade,
							resit: obj.resit,
							registered: obj.registered,
							passed: obj.passed,
							is_published: obj.published,
						},
						course_details: obj.courses.courses[0],
					});

					return acc;
				}, {});

				levelBasket.push({ key: key, acc: item });
			}
			state.student_level_course = levelBasket;
			console.log(levelBasket);
		},
		SET_TRANSCRIPT: (state, transcript) => (state.transcript = transcript),
	},

	actions: {
		async getLevelCourse({ commit }, basket) {
			const response = await Api()
				.post("/getlevelcourse", { basket })
				.then((res) => res.data);
			commit("SET_LEVEL_COURSE", response.data);
		},

		async getTranscript({ commit }, basket) {
			const response = await Api()
				.post("/gettranscript", { basket })
				.then((res) => res.data);
			commit("SET_TRANSCRIPT", response.data);
		},

		
	},
};
