import Api from "@/apis/Api";

export default {
	state: {
		calendar: [],
		curcalendar: [],
		alreadyExist: {},
		event: [],
	},
	getters: {
		getters_calendar: (state) => state.calendar,
		getters_event: (state) => state.event,
		getters_curcalendar: (state) => state.curcalendar,
		getters_calendaralreadyexist: (state) => state.alreadyExist,
	},
	mutations: {
		SET_UPDATE_EVENT: (state, event) => (state.event = event),
		SET_CALENDAR: (state, calendar) => (state.calendar = calendar),
		SET_EVENT: (state, event) => (state.event = event),
		SET_SAVECALENDAR: (state, calendarBasket) => {
			state.calendar.forEach((element) => {
				element.current = false;
			});
			state.calendar.splice(0, 0, calendarBasket);
		},
		ADD_NEW_EVENT: (state, eventBasket) => {
			state.event.push(eventBasket);
		},
		SET_CURCALENDAR: (state, curcalendar) => (state.curcalendar = curcalendar),
		SET_ALREADYEXISTCALENDAR: (state, alreadyExist) =>
			(state.alreadyExist = alreadyExist),
		REMOVE_CALENDAR: (state, calendar_id) => {
			state.calendar.filter((calendar, index) => {
				calendar.id === calendar_id ? state.calendar.splice(index, 1) : null;
			});
		},
		DELETE_EVENT: (state, event_id) => {
			state.event.filter((event, index) => {
				event.id === event_id ? state.event.splice(index, 1) : null;
			});
		},
	},
	actions: {
		async getCalendar({ commit }) {
			const response = await Api()
				.get("/calendar")
				.then((res) => res.data);
			commit("SET_CALENDAR", response.data);
		},

		async getCurCalendar({ commit }) {
			const response = await Api()
				.get("/curcalendar")
				.then((res) => res.data);
			commit("SET_CURCALENDAR", response.data);
		},

		async saveCalendar({ commit /* dispatch */ }, calendarBasket) {
			await Api()
				.post("/savecalendar", { calendarBasket })
				.then((res) => {
					/* if (res.data.is_exists !== true) { */
					commit("SET_SAVECALENDAR", res.data);
					//return dispatch("getCurCalendar");
					/* }

					commit("SET_ALREADYEXISTCALENDAR", calendarBasket); */
				});
		},

		async removeCalendar({ commit }, calendar_id) {
			await Api()
				.delete(`/removecalendar/${calendar_id}`)
				.then(() => commit("REMOVE_CALENDAR", calendar_id));
		},

		async getEvent({ commit }, event) {
			const response = await Api()
				.post("/getevent", { event })
				.then((res) => res.data);
			commit("SET_EVENT", response.data);
		},

		async updateEvent({ commit }, eventBasket) {
			await Api()
				.post("/updateevent", { eventBasket })
				.then((res) => commit("SET_UPDATE_EVENT", res.data));
		},

		async deleteEvent({ commit }, event_id) {
			await Api()
				.delete(`/deleteevent/${event_id}`)
				.then(() => commit("DELETE_EVENT", event_id));
		},

		async addNewEvent({ commit }, eventBasket) {
			await Api()
				.post("/addevent", { eventBasket })
				.then((res) => {
					commit("ADD_NEW_EVENT", res.data.data);
				});
		},
	},
};
