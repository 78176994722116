export default {
  state: {
    page: false,
  },
  getters: {
    getters_open_edit_page: (state) => state.page,
  },
  mutations: {
    OPEN_EDIT_PAGE: (state, page) => (state.page = page),
  },
  actions: {
    openEditPage({ commit }, page) {
      commit("OPEN_EDIT_PAGE", page);
    },
  },
};
