import Api from "@/apis/Api";

export default {
  state: {
    program: [],
    program_paginate: [],
    program_details: [],
    meta: [],
    program_department: [],
    grades: [],
    user_grade_system: {},
    hodProg: [],
    promote_students: {}
  },
  getters: {
    getters_program: (state) => state.program,
    getters_program_paginate: (state) => state.program_paginate,
    getters_program_meta: (state) => state.meta,
    getters_program_details: (state) => state.program_details,
    getters_program_department: (state) => state.program_department,
    getters_grades: (state) => state.grades,
    getters_user_grade_system: (state) => state.user_grade_system,
    getters_hoddeptprog: (state) => state.hodProg,
    getters_promote_students: (state) => state.promote_students
  },
  mutations: {
    SET_PROMOTE_STUDENTS: (state, data) => (state.promote_students = data),
    SET_PROGRAM_META: (state, data) => (state.meta = data),
    SET_PROGRAM_PAGINATE: (state, program) =>
      (state.program_paginate = program),
    SET_PROGRAM: (state, program) => (state.program = program),
    SET_SAVE_PROGRAM: (state, program) => state.program.push(program),
    SET_PROGRAM_DETAILS: (state, program_details) =>
      (state.program_details = program_details),
    REMOVE_PROGRAM: (state, program_id) => {
      state.program.filter((program, index) => {
        program.id === program_id ? state.program.splice(index, 1) : null;
      });
    },
    SET_PROGRAM_DEPARTMENT: (state, data) => (state.program_department = data),
    SET_GRADES: (state, grades) => (state.grades = grades),
    GET_HOD_DEPT_PROG: (state, programs) => state.hodProg = programs,
    SET_PROMOTE_STUDENTS_NEW_LEVEL: (state, data) => state.promote_students.data1.push(data),
  },
  actions: {
    async getPaginateProgram({ commit }, page_number) {
      page_number = page_number || 1;
      const response = await Api()
        .get("/program_paginate?page=" + page_number)
        .then((res) => res.data);
      commit("SET_PROGRAM_PAGINATE", response.data);
      commit("SET_PROGRAM_META", response.meta);
    },

    async getProgram({ commit }) {
      const response = await Api()
        .get("/all_program")
        .then((res) => res.data);
      commit("SET_PROGRAM", response.data);
    },
    async promoteStudents({ commit }, data) {
      const response = await Api()
        .post("/promotestudents", { data })
        .then((res) => res.data);
      commit("SET_PROMOTE_STUDENTS_NEW_LEVEL", response.data);
    },

    async getPromoteStudents({ commit },) {
      const response = await Api()
        .get("/getprogramlevelforstudentpromotion")
        .then((res) => res.data);
      commit("SET_PROMOTE_STUDENTS", response.data);
    },

    async getProgramDetails({ commit }, id) {
      const response = await Api()
        .post("/getprogramdetails", { id })
        .then((res) => res.data);
      commit("SET_PROGRAM_DETAILS", response.data);
    },

    async saveProgram({ commit }, programBasket) {
      await Api()
        .post("/saveprogram", { programBasket })
        .then(() => commit("SET_SAVE_PROGRAM", programBasket));
    },

    async removeProgram({ commit }, program_id) {
      await Api()
        .delete(`/removeprogram/${program_id}`)
        .then((res) => res.data);
      commit("REMOVE_PROGRAM", program_id);
    },

    async getProgramDepartment({ commit }, program_id) {
      const response = await Api()
        .post("/programdepartment", { program_id })
        .then((res) => res.data);
      commit("SET_PROGRAM_DEPARTMENT", response.data);
    },

    async saveGrades({ commit }, grades) {
      const response = await Api()
        .post("/savegrades", { grades })
        .then((res) => res.data);
      commit("SET_GRADES", response.data);
    },

    async getGrades({ commit }) {
      const response = await Api()
        .get("/grades")
        .then((res) => res.data);
      commit("SET_GRADES", response.data);
    },

    async getStudentGradesSystem({ commit }, index_number) {
      const response = await Api()
        .post("/getstudentgradessystem", { index_number })
        .then((res) => res.data);
      commit("SET_USER_GRADES_SYS", response.data);
    },

    async getHodDeptProg({ commit }, hod) {
      const response = await Api()
        .post("/gethoddeptprog", { hod })
        .then((res) => res.data);
      commit("GET_HOD_DEPT_PROG", response.data);
    },
  },
};
