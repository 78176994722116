import Api from "@/apis/Api";

export default {
  state: {
    organogram: [],
    alreadyExist: {},
    officeHolder: []
  },
  getters: {
    getters_organogram: (state) => state.organogram,
    getters_organogramalreadyexist: (state) => state.alreadyExist,
    getters_office_holders: (state) => state.officeHolder
  },
  mutations: {
    SET_ORGANOGRAM: (state, organogram) => (state.organogram = organogram),
    SET_OFFICE: (state, office) => state.organogram.push(office),
    SET_OFFICE_HOLDER: (state, office) => state.officeHolder.push(office),
    OFFICE_HOLDERS: (state, office) => state.officeHolder = office
  },
  actions: {
    async getOfficeHolder({ commit }) {
      await Api()
        .get('getofficeholder')
        .then((res) => commit('OFFICE_HOLDERS', res.data.data))
    },

    async getOrganogram({ commit }) {
      const response = await Api()
        .get("/organogram")
        .then((res) => res.data);
      commit("SET_ORGANOGRAM", response.data);
    },

    async saveOffice({ commit }, basket) {
      const response = await Api()
        .post("/saveoffice", { basket })
        .then((res) => res.data);
      commit("SET_OFFICE", response.data);
    },

    async saveOfficeHolder({ commit }, basket) {
      const response = await Api()
        .post("/saveofficeholder", { basket })
        .then((res) => res.data);
      commit("SET_OFFICE_HOLDER", response.data);
    }
  },
};
