import Api from "@/apis/Api";

export default {
  state: {
    faculty: [],
    checkfacultyavail: false,
    curr_faculty: null,
    duplicate_faculty_name: null,
    meta: [],
  },
  getters: {
    getters_faculty: (state) => state.faculty,
    getters_checkfacultyavail: (state) => state.checkfacultyavail,
    getters_currfaculty: (state) => state.curr_faculty,
    getters_dup_fac_name: (state) => state.duplicate_faculty_name,
  },
  mutations: {
    SET_CHECKFACULTYAVAIL: (state, checkfacultyavail) =>
      (state.checkfacultyavail = checkfacultyavail),
    SET_FACULTY: (state, faculty) => (state.faculty = faculty),
    CURR_FACULTY: (state, curr_faculty) => (state.curr_faculty = curr_faculty),
    SAVE_FACULTY: (state, facultyBasket) => {
      state.faculty.push(facultyBasket);
    },
    REMOVE_FACULTY: (state, faculty_id) => {
      state.faculty.filter((faculty, index) => {
        faculty.id === faculty_id ? state.faculty.splice(index, 1) : null;
      });
    },
    SAVE_FACULTY_CHANGES: (state, data) => {
      state.faculty.forEach((element) => {
        element.id === data.id && element.faculty_id === data.faculty_id
          ? (element.faculty_name = data.text)
          : null;
      });
      state.duplicate_faculty_name = null;
    },
    DUPLICATE_FACULTY_NAME: (state, data) =>
      (state.duplicate_faculty_name = data),
  },
  actions: {
    async getFaculty({ commit }) {
      const response = await Api()
        .get("/faculty")
        .then((res) => res.data);
      commit("SET_FACULTY", response.data);
    },

    async saveFaculty({ commit }, facultyBasket) {
      await Api()
        .post("/savefaculty", { facultyBasket })
        .then(() => commit("SAVE_FACULTY", facultyBasket));
    },

    async checkFacultyAvail({ commit }, faculty_id) {
      await Api()
        .post("/checkfacultyavail", { faculty_id })
        .then((res) => {
          commit("SET_CHECKFACULTYAVAIL", res.data);
        });
    },

    async getCurrentFaculty({ commit }, curr_faculty) {
      await Api()
        .post("/getcurrfaculty", { curr_faculty })
        .then((res) => {
          commit("CURR_FACULTY", res.data);
        });
    },

    async removeFaculty({ commit }, faculty_id) {
      await Api()
        .delete(`/removefaculty/${faculty_id}`)
        .then((res) => {
          res.data === "deleted" ? commit("REMOVE_FACULTY", faculty_id) : "";
        });
    },

    async saveFacultyChanges({ commit }, faculty) {
      await Api()
        .post("/savefacultychanges", { faculty })
        .then((res) => {
          if (res.data) {
            commit("SAVE_FACULTY_CHANGES", faculty);
            return;
          } else {
            commit("DUPLICATE_FACULTY_NAME", faculty.text);
          }
        });
    },
  },
};
