import Api from "@/apis/Api";

export default {
  state: {
    role: [],
    user_role: []
  },
  getters: {
    getters_role: (state) => state.role,
    getters_rolealreadyexist: (state) => state.alreadyExist,
    getters_user_role: (state) => state.user_role
  },
  mutations: {
    USER_ROLE: (state, role) => state.user_role = role,
    SET_ROLE: (state, role) => (state.role = role),
    SET_SAVEROLE: (state, roleBasket) => {
      state.role.push(roleBasket);
    },

    REMOVE_ROLE: (state, role_id) => {
      state.role.filter((role, index) => {
        role.id === role_id ? state.role.splice(index, 1) : null;
      });
    },
    UPDATE_ROLE_PERMISSION: (state, data) => {
      const {
        old: { role },
      } = data;
      state.role.forEach((item) => {
        if (item.id === role) {
          item.permissions = [];
          data.new.forEach((element) => {
            item.permissions.push(element);
          });
        }
      });
    },
  },

  actions: {
    async getRole({ commit }) {
      const response = await Api()
        .get("/all_roles")
        .then((res) => res.data);
      commit("SET_ROLE", response.data);
    },

    async saveRole({ commit }, roleBasket) {
      await Api()
        .post("/saverole", { roleBasket })
        .then((res) => {
          commit("SET_SAVEROLE", res.data.data);
        });
    },

    async removeRole({ commit }, role_id) {
      await Api()
        .delete(`/removerole/${role_id}`)
        .then((res) => {
          res.data === "deleted" ? commit("REMOVE_ROLE", role_id) : "";
        });
    },

    async updateRolePermission({ commit }, roleBasket) {
      await Api()
        .post("/updaterrolepermission", { roleBasket })
        .then((res) => {
          commit("UPDATE_ROLE_PERMISSION", {
            old: roleBasket,
            new: res.data.data,
          });
        });
    },

    async userRole({ commit }, staff_id) {
      await Api()
        .post("/user_role", { staff_id })
        .then((res) => {
          commit("USER_ROLE", res.data);
        });
    },


  },
};
