import Api from "@/apis/Api";

export default {
  state: {
    level: [],
    alreadyExist: {},
    studentpromotion: []
  },
  getters: {
    getters_level: (state) => state.level,
    getters_levelalreadyexist: (state) => state.alreadyExist,
    getters_promote_student: (state) => state.studentpromotion
  },
  mutations: {
    SET_LEVEL: (state, level) => (state.level = level),
    SET_STUDENT_PROMOTION: (state, studentpromotion) => (state.studentpromotion = studentpromotion),
    SET_SAVE_LEVEL: (state, levelBasket) => {
      state.level.push(levelBasket);
    },
    SET_ALREADYEXISTLEVEL: (state, alreadyExist) =>
      (state.alreadyExist = alreadyExist),

    REMOVE_LEVEL: (state, level_id) => {
      state.level.filter((level, index) => {
        level.id === level_id ? state.level.splice(index, 1) : null;
      });
    },
  },
  actions: {
    async getLevel({ commit }) {
      const response = await Api()
        .get("/level")
        .then((res) => res.data);
      commit("SET_LEVEL", response.data);
    },
    async promoteStudent({ commit }, data) {
      const response = await Api()
        .post("/promotestudent", { data })
        .then((res) => res.data);
      commit("SET_STUDENT_PROMOTION", response.data);
    },
    async saveLevel({ commit }, levelBasket) {
      await Api()
        .post("/savelevel", { levelBasket })
        .then((res) => commit("SET_SAVE_LEVEL", res.data));
    },
    async removeLevel({ commit }, level_id) {
      await Api()
        .delete(`/removelevel/${level_id}`)
        .then(() => commit("REMOVE_LEVEL", level_id));
    },
  },
};
