/** @format */

import axios from "axios";

let BaseApi = axios.create({
   baseURL: "https://sms-backend.gctu.edu.gh/public/api",
   //baseURL: "http://localhost:8000/api",
  // baseURL: "https://sms-backend.kuulvibes.com/api",
});

let Api = () => {
  let token = localStorage.getItem("token");
  if (token) {
    BaseApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    //BaseApi.defaults.headers.common["X-Socket-Id"] = window.Echo.socketId();
  }
  return BaseApi;
};

export default Api;
