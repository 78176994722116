/** @format */

import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
/* import { uuid } from "vue-uuid";
const namespace = "65f9af5d-f23f-4065-ac85-da725569fdcd" */

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",
    redirect: {
      name: "Login",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
    meta: {
      guestOnly: true,
      title: "SMS - Login",
    },
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("@/views/Home.vue"),
    meta: {
      authOnly: true,
      title: "SMS - DASHBOARD",
    },
    props: true,
  },
  {
    path: "/setup",
    name: "View Setup",
    component: () => import("@/views/Setup.vue"),
    meta: {
      authOnly: true,
      title: "SMS - SETUP",
    },
  },
  {
    path: "/course_management/newcourse",
    name: "New Course",
    component: () => import("@/views/Course/NewCourse.vue"),
    meta: {
      authOnly: true,
      title: "SMS - NEW COURSE",
    },
  },
  {
    path: "/course_management/courses",
    name: "Courses",
    component: () => import("@/views/Course/Courses.vue"),
    meta: {
      authOnly: true,
      title: "SMS - COURSE LIST",
    },
  },
  {
    path: "/course_management/coursehistory",
    name: "Course History",
    component: () => import("@/views/Course/MyCourseHistory.vue"),
    meta: {
      authOnly: true,
      title: "SMS - COURSE HISTORY",
    },
  },
  {
    path: "/course_management/assigntolecturer",
    name: "Assign Courses to Lecturers",
    component: () => import("@/views/Course/AssignToLecturer.vue"),
    meta: {
      authOnly: true,
      title: "SMS - ASSIGN COURSES",
    },
  },
  {
    path: "/course_management/mountcourse",
    name: "Mount Courses",
    component: () => import("@/views/Course/MountCourse.vue"),
    meta: {
      authOnly: true,
      title: "SMS - MOUNT COURSE",
    },
  },
  {
    path: "/pdfview",
    name: "PdfViewer",
    component: () => import("@/views/Viewer/PdfViewer.vue"),
    meta: {
      authOnly: true,
      title: "SMS - PDF VIEWER",
    },
  },
  {
    path: "/document_management/list",
    name: "Letters",
    component: () => import("@/views/Document/Letter.vue"),
    meta: {
      authOnly: true,
      title: "SMS - LETTERS",
    },
  },
  {
    path: "/document_management",
    name: "Documents",
    meta: {
      authOnly: true,
      title: "SMS - DOCUMENTS",
    },
    redirect: {
      name: "Letters",
    },
  },
  {
    path: "/staff_management/stafflist",
    name: "StaffList",
    component: () => import("@/views/Staff/StaffList.vue"),
    props: true,
    meta: {
      authOnly: true,
      title: "SMS - STAFF LIST",
    },
    children: [
      {
        props: true,
        meta: {
          authOnly: true,
          title: "SMS - STAFF",
        },
        path: ":/staff/:id",
        component: () => import("@/components/Staff/ViewStaff"),
        name: "CurrentStaff",
      },
      {
        props: true,
        meta: {
          authOnly: true,
          title: "SMS - STAFF ROLE",
        },
        path: ":/role/:id",
        component: () => import("@/components/Staff/RolePermission/Role"),
        name: "StaffRole",
      },
      {
        props: true,
        meta: {
          authOnly: true,
          title: "SMS - NEW STAFF",
        },
        path: ":/addstaff",
        component: () => import("@/components/Staff/View/NewStaff"),
        name: "AddStaff",
      },
      {
        props: true,
        meta: {
          authOnly: true,
          title: "SMS - ORGANOGRAM",
        },
        path: ":/organogram",
        component: () => import("@/components/Staff/OfficeHolder"),
        name: "Organogram",
      },
    ],
  },
  {
    path: "/faculty_management/new_faculty",
    name: "NewFaculty",
    component: () => import("@/views/Faculty/NewFaculty.vue"),
    meta: {
      authOnly: true,
      title: "SMS - NEW FACULTY",
    },
    props: true,
  },
  {
    path: "/faculty_management/faculty_list",
    name: "FacultyList",
    component: () => import("@/views/Faculty/FacultyList.vue"),
    meta: {
      authOnly: true,
      title: "SMS - FACULTY LIST",
    },
    props: true,
  },
  {
    path: "/student_management/addstudent",
    name: "Add Student",
    component: () => import("@/views/Student/AddStudent.vue"),
    meta: {
      authOnly: true,
      title: "SMS - ADD STUDENT",
    },
  },
  {
    path: "/student_management/promotion",
    name: "Promote Student",
    component: () => import("@/views/Student/PromoteStudent.vue"),
    meta: {
      authOnly: true,
      title: "SMS - PROMOTE STUDENT",
    },
  },
  {
    path: "/student_management/studentstatus",
    name: "Student Status",
    component: () => import("@/views/Student/StudentStatus.vue"),
    meta: {
      authOnly: true,
      title: "SMS - STUDENT STATUS",
    },
    props: true,
  },
  {
    path: "/finance_management/fees",
    name: "Fees",
    component: () => import("@/views/Finance/Fees.vue"),
    meta: {
      authOnly: true,
      title: "SMS - FEES",
    },
    props: true,
  },
  {
    path: "/finance_management/feeslist",
    name: "FeesList",
    component: () => import("@/views/Finance/FeesList.vue"),
    meta: {
      authOnly: true,
      title: "SMS - FEES LIST",
    },
    props: true,
  },
  {
    path: "/finance_management/enrolstudent",
    name: "Enrol Student",
    component: () => import("@/views/Finance/EnrolStudent.vue"),
    meta: {
      authOnly: true,
      title: "SMS - ENROL STUDENT",
    },
    props: true,
  },
  {
    path: "/finance_management/financialstatement",
    name: "Financial Statement",
    component: () => import("@/views/Finance/FinancialStatement.vue"),
    meta: {
      authOnly: true,
      title: "SMS - FINANCIAL STATEMENT",
    },
    props: true,
  },
  {
    path: "/finance_management/enrolledstudent",
    name: "Enrolled Students",
    component: () => import("@/views/Finance/ViewEnrolledStudent.vue"),
    meta: {
      authOnly: true,
      title: "SMS - ENROLLED STUDENTS",
    },
    props: true,
  },
  {
    path: "/finance_management/miscellaneous",
    name: "Miscellaneous",
    component: () => import("@/views/Finance/Miscellaneous.vue"),
    meta: {
      authOnly: true,
      title: "SMS - MISCELLANEOUS",
    },
    props: true,
  },
  {
    path: "/finance_management/miscpay",
    name: "Miscellaneous Payment",
    component: () => import("@/views/Finance/MiscPay.vue"),
    meta: {
      authOnly: true,
      title: "SMS - MISCELLANEOUS PAYMENT",
    },
    props: true,
  },
  {
    path: "/finance_management/lateregistration",
    name: "Late Registration",
    component: () => import("@/views/Finance/LateRegistration.vue"),
    meta: {
      authOnly: true,
      title: "SMS - LATE REGISTRATION",
    },
    props: true,
  },
  {
    path: "/student_management/searchstudent",
    name: "Search Student",
    component: () => import("@/views/Student/SearchStudent.vue"),
    meta: {
      authOnly: true,
      title: "SMS - SEARCH STUDENT",
    },
    props: true,
  },
  {
    path: "/student_management/resultslip",
    name: "Result Slip",
    component: () => import("@/views/Student/ResultSlip.vue"),
    meta: {
      authOnly: true,
      title: "SMS - RESULT SLIP",
    },
    props: true,
  },
  {
    path: "/student_management/transcript",
    name: "Transcript",
    component: () => import("@/views/Student/Transcript.vue"),
    meta: {
      authOnly: true,
      title: "SMS - RESULT SLIP",
    },
    props: true,
  },
  {
    path: "/course_management/broadsheet",
    name: "Broadsheet",
    component: () => import("@/views/Course/Broadsheet.vue"),
    meta: {
      authOnly: true,
      title: "BROADSHEET",
    },
    props: true,
  },
  {
    path: "/course_management/mycourses",
    name: "My Courses",
    component: () => import("@/views/Course/MyCourses.vue"),
    meta: {
      authOnly: true,
      title: "MY COURSES",
    },
    props: true,
  },
  {
    path: "/course_management/resit",
    name: "Resit Courses",
    component: () => import("@/views/Course/MyResitCourses.vue"),
    meta: {
      authOnly: true,
      title: "RESIT COURSES",
    },
    props: true,
  },
  {
    path: "/course_management/retake",
    name: "Retake Courses",
    component: () => import("@/views/Course/MyRetakeCourses.vue"),
    meta: {
      authOnly: true,
      title: "RETAKE COURSES",
    },
    props: true,
  },
  {
    path: "/access_control_management/permission",
    name: "Permission",
    component: () => import("@/views/AccessControl/Permission.vue"),
    meta: {
      authOnly: true,
    },
    props: true,
    title: "STAFF - PERMISSIONS",
  },
  {
    path: "/access_control_management/role",
    name: "Role",
    component: () => import("@/views/AccessControl/Role.vue"),
    meta: {
      authOnly: true,
      title: "SMS - ROLE",
    },
    children: [
      {
        props: true,
        meta: {
          authOnly: true,
          title: "SMS - ROLE PERMISSIONS",
        },
        path: ":/rolepermission/:id",
        component: () => import("@/components/Access/RolePermission"),
        name: "RolePermission",
      },
    ],
  },
  /* {
    path: "/access_control_management/assignrole",
    name: "AssignRole",
    component: () => import("@/views/AccessControl/AssignRole.vue"),
    meta: {
      authOnly: true,
      title: "SMS - ASSIGN ROLE",
    },
  }, */
  {
    path: "/access_control_management/deadline",
    name: "Deadline",
    component: () => import("@/views/AccessControl/Deadline.vue"),
    meta: {
      authOnly: true,
    },
    props: true,
  },
  {
    path: "/department_management/newdepartment",
    name: "NewDepartment",
    component: () => import("@/views/Department/NewDepartment.vue"),
    meta: {
      authOnly: true,
      title: "SMS - NEW DEPARTMENT",
    },
    props: true,
  },
  {
    path: "/holds_management/uploads",
    name: "Uploads",
    component: () => import("@/views/Holds/Uploads.vue"),
    meta: {
      authOnly: true,
      title: "SMS - UPLOADS",
    },
    props: true,
  },
  {
    path: "/holds_management/broadsheet",
    name: "HoldsBroadsheet",
    component: () => import("@/views/Holds/Broadsheet.vue"),
    meta: {
      authOnly: true,
      title: "SMS - BROADSHEET",
    },
    props: true,
  },
  {
    path: "/department_management/departmentlist",
    name: "DepartmentList",
    component: () => import("@/views/Department/DepartmentList.vue"),
    meta: {
      authOnly: true,
      title: "DEPARTMENT LIST",
    },
    props: true,
  },
  {
    path: "/program_management/newprogram",
    name: "NewProgram",
    component: () => import("@/views/Program/NewProgram.vue"),
    meta: {
      authOnly: true,
      title: "SMS - NEW PROGRAM",
    },
    props: true,
  },
  {
    path: "/program_management/programlist",
    name: "ProgramList",
    component: () => import("@/views/Program/ProgramList.vue"),
    meta: {
      authOnly: true,
      title: "SMS - PROGRAM LIST",
    },
    props: true,
  },
  {
    path: "/holds_management/probation",
    name: "Probation",
    component: () => import("@/views/Holds/ProbationList.vue"),
    meta: {
      authOnly: true,
      title: "SMS - PROBATION",
    },
    props: true,
  },
  {
    path: "/course_management/headers",
    name: "Header",
    component: () => import("@/views/Course/Headers.vue"),
    meta: {
      authOnly: true,
      title: "SMS - REGISTRATION STATUS",
    },
    props: true,
  },
  {
    path: "/quality_assurance_management/overview",
    name: "Quality Assurance Overview",
    component: () => import("@/views/QualityAssurance/Overview.vue"),
    props: true,
    meta: {
      authOnly: true,
      title: "SMS - QUALITY ASSURANCE",
    },
  },
  {
    name: "Questions",
    props: true,
    meta: {
      authOnly: true,
      title: "SMS - EVALUATION TEMPLATE",
    },
    path: "/quality_assurance_management/questions",
    component: () => import("@/views/QualityAssurance/Questions.vue"),
    children: [
      {
        props: true,
        meta: {
          authOnly: true,
          title: "SMS - ADD QUESTION",
        },
        path: ":/addquestion",
        component: () => import("@/components/QualityAssurance/AddQuestion"),
        name: "AddQuestion",
      },
    ],
  },
  {
    name: "StaffAppraisal",
    props: true,
    meta: {
      authOnly: true,
      title: "SMS - STAFF APPRAISAL",
    },
    path: "/staff_appraisal_management/appraisal",
    component: () => import("@/views/Appraisal/AppraisalTemplate.vue"),
    /*  children: [
       {
         props: true,
         meta: {
           authOnly: true,
           title: "SMS - ADD QUESTION",
         },
         path: ":/addquestion",
         component: () => import("@/components/QualityAssurance/AddQuestion"),
         name: "AddQuestion",
       },
     ] */
  },
  {
    path: "/quality_assurance_management/assessment",
    name: "Assessment",
    component: () => import("@/views/QualityAssurance/Assessment.vue"),
    props: true,
    meta: {
      authOnly: true,
      title: "SMS - QUALITY ASSURANCE ASSESSMENT",
    },
  },
  {
    name: "Script Logs",
    props: true,
    meta: {
      authOnly: true,
      title: "SMS - LOGS",
    },
    path: "/logs_management/scriptlog",
    component: () => import("@/views/Logs/Overview"),
  },
  {
    name: "User Logs",
    props: true,
    meta: {
      authOnly: true,
      title: "SMS - USER LOGS",
    },
    path: "/logs_management/userlog",
    component: () => import("@/views/Logs/User"),
  },
  {
    name: "PCLS",
    props: true,
    meta: {
      authOnly: true,
      title: "SMS - PROGRAM COURSE LEVEL SEMESTER",
    },
    path: "/course_management/pcls",
    component: () => import("@/views/Course/PCLS"),
  },
  {
    name: "Leave Setup",
    props: true,
    meta: {
      authOnly: true,
      title: "SMS - LEAVE SETUP",
    },
    path: "/leave_management/setup",
    component: () => import("@/views/Leave/Setup"),
  },
  {
    name: "Leave Summary",
    props: true,
    meta: {
      authOnly: true,
      title: "SMS - LEAVE SUMMARY",
    },
    path: "/leave_management/summary",
    component: () => import("@/views/Leave/Summary"),
  },
  {
    name: "Leave Approval",
    props: true,
    meta: {
      authOnly: true,
      title: "SMS - LEAVE APPROVAL STATUS",
    },
    path: "/leave_management/leaveapproval",
    component: () => import("@/views/Leave/LeaveApproval"),
  },
  {
    name: "Leave Notification",
    props: true,
    meta: {
      authOnly: true,
      title: "SMS - LEAVE NOTIFICATION",
    },
    path: "/leave_management/notification",
    component: () => import("@/views/Leave/NotificationLeave"),
  },
  {
    path: "*",
    component: () => import("@/views/PageNotFound.vue"),
    meta: { title: "SMS - PAGE NOT FOUND" },
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
  base: process.env.BASE_URL,
});

let isLoggedIn = () => {
  return localStorage.getItem("token");
};

const hasAccess = (name) => {
  if (store.getters["user"]) {
    const userRolePermission = store.getters["user"].roles.flatMap(
      (access) => access.permissions
    );

    const permissions = userRolePermission.map((access) => access.title);

    switch (name) {
      case "Home":
        return true;

      case "Promote Student":
        return permissions.includes("student_promotion_access");

      case "Leave Summary":
        return permissions.includes("leave_summary_access");

      case "Leave Setup":
        return permissions.includes("leave_setup_access");

      case "View Setup":
        return permissions.includes("setup_management_access");

      case "Add Student":
        return permissions.includes("add_student_access");

      case "Script Logs":
        return permissions.includes("logs_management_access");

      case "User Logs":
        return permissions.includes("logs_management_access");

      case "AppraisalTemplate":
        return permissions.includes("new_appraisal_access");

      case "StaffAppraisal":
        return permissions.includes("staff_appraisal_management_access");

      case "Assessment":
        return permissions.includes("assessment_access");

      case "Quality Assurance Overview":
        return permissions.includes("quality_assurance_overview_access");

      case "Questions":
        return permissions.includes("evaluation_question_access");

      case "AddQuestion":
        return permissions.includes("save_question_access");

      case "New Course":
        return permissions.includes("new_course_access");

      case "Courses":
        return permissions.includes("available_courses_access");

      case "PCLS":
        return permissions.includes("available_courses_access");

      case "My Courses":
        return permissions.includes("my_courses_access");

      case "Resit Courses":
        return permissions.includes("my_courses_access");

      case "Retake Courses":
        return permissions.includes("my_courses_access");

      case "Course History":
        return permissions.includes("my_courses_access");

      case "Mount Courses":
        return permissions.includes("mount_courses_access");

      case "Assign Courses to Lecturers":
        return permissions.includes("assign_lecturer_access");

      case "Result Slip":
        return permissions.includes("result_slip_access");

      case "Transcript":
        return permissions.includes("result_slip_access");

      case "Student Status":
        return permissions.includes("student_status_access");

      case "Search Student":
        return permissions.includes("search_student_access");

      case "Faculty":
        return permissions.includes("faculty_management_access");

      case "AddStaff":
        return permissions.includes("create_staff_access");

      case "StaffList":
        return permissions.includes("staff_list_access");

      case "CurrentStaff":
        return permissions.includes("staff_list_access");

      case "Enrolled Students":
        return permissions.includes("enrolled_student_access");

      case "Fees":
        return permissions.includes("fees_access");

      case "FeesList":
        return permissions.includes("fees_list_access");

      case "Financial Statement":
        return permissions.includes("financial_statement_access");

      case "Enrol Student":
        return permissions.includes("enrol_student_access");

      case "Miscellaneous":
        return permissions.includes("miscellaneous_fees_access");

      case "Miscellaneous Payment":
        return permissions.includes("others_fees_access");

      case "Late Registration":
        return permissions.includes("enrol_student_access");

      case "Permission":
        return permissions.includes("permission_access");

      case "Role":
      case "Letters":
      case "Documents":
        return permissions.includes("role_access");

      case "StaffRole":
        return permissions.includes("role_access");

      case "RolePermission":
        return permissions.includes("role_access");

      case "Deadline":
        return permissions.includes("deadline_access");

      case "Broadsheet":
        return permissions.includes("broadsheet_access");

      case "Uploads":
        return permissions.includes("header_access");

      case "HoldsBroadsheet":
        return permissions.includes("header_access");

      case "NewFaculty":
        return permissions.includes("new_faculty_access");

      case "FacultyList":
        return permissions.includes("faculty_list_access");

      case "NewDepartment":
        return permissions.includes("new_department_access");

      case "DepartmentList":
        return permissions.includes("department_list_access");

      case "NewProgram":
        return permissions.includes("new_program_access");

      case "ProgramList":
        return permissions.includes("program_list_access");

      case "Probation":
        return permissions.includes("probation_access");

      case "Course List":
        return permissions.includes("allcourses_access");

      case "Header":
        return permissions.includes("header_access");

      case "Leave Approval":
      case "Leave Notification":
        return permissions.includes("leave_approval_access");

      /* case "AssignRole":
        return permissions.includes('assign_role_access') */

      default:
        return false;
    }
  } else {
    return false;
  }
};

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.authOnly)) {
    if (!isLoggedIn()) {
      next({
        path: "/login",
        query: {
          redirect: to.fullPath,
        },
      });
    } else {
      if (
        !hasAccess(to.name) &&
        store.getters["user"] &&
        store.getters["getters_authorized"] &&
        store.getters["authenticated"]
      ) {
        next(`/home`);
      } else if (
        !hasAccess(to.name) &&
        !store.getters["user"] &&
        (!store.getters["getters_authorized"] ||
          !store.getters["authenticated"])
      ) {
        next({
          path: "/login",
          query: {
            redirect: to.fullPath,
          },
        });
      } else {
        next();
      }
    }
  } else if (to.matched.some((record) => record.meta.guestOnly)) {
    if (
      isLoggedIn() &&
      store.getters["getters_authorized"] &&
      store.getters["authenticated"]
    ) {
      if (to.matched.some((record) => record.name === "Login")) next(`/home`);
      //else next();
    } else next();
  } else next();
});

export default router;
