/** @format */

import Api from "@/apis/Api";
import auth from "./auth";
export default {
	state: {
		authenticated: false,
		activeStaff: [],
		staffPaginate: [],
		meta: {},
		staff: null,
		staffRole: {},
		deptStaff: [],
		authStaff: [],
		staffAuthLog: [],
		staffAuthLogMeta: {},
		positions: [],
		categories: [],
		divisions: [],
		leave: [],
		leaveRequest: [],
		pendingLeave: [],
		approvedLeave: [],
		staffLeave: [],
		notifications: [],
		pendingCount: 0,
	},
	getters: {
		getters_active_staff: (state) => state.activeStaff,
		getters_staff: (state) => state.staffPaginate,
		getters_staff_meta: (state) => state.meta,
		getters_single_staff: (state) => state.staff,
		getters_staff_role: (state) => state.staffRole,
		getters_dept_staff: (state) => state.deptStaff,
		getters_auth_staff: (state) => state.authStaff,
		getters_staff_auth_logs: (state) => state.staffAuthLog,
		getters_staff_auth_logs_meta: (state) => state.staffAuthLogMeta,
		getters_staff_categories: (state) => state.categories,
		getters_staff_divisions: (state) => state.divisions,
		getters_staff_positions: (state) => state.positions,
		getters_staff_leave: (state) => state.leave,
		getters_staff_leave_request: (state) => state.leaveRequest,
		getters_staff_approved_leave: (state) => state.approvedLeave,
		getters_mystaff_leave: (state) => state.staffLeave,
		getters_staff_pending_leave: (state) => state.pendingLeave,
		getters_staff_notification: (state) => state.notifications,
		getters_staff_count_pending: (state) => state.pendingCount,
	},
	mutations: {
		SET_ACTIVE_STAFF: (state, value) => (state.activeStaff = value),
		SET_STAFF: (state, staff) => (state.staffPaginate = staff),
		SET_STAFF_META: (state, meta) => (state.meta = meta),
		SET_SINGLE_STAFF: (state, singleStaff) => {
			state.staff = singleStaff;
			const index = state.staffPaginate.findIndex(
				(item) => item.id === singleStaff.id
			);
			if (index > -1) state.staffPaginate.splice(index, 1, singleStaff);
			else state.staffPaginate.push(singleStaff);

			auth.state.user.avatar_url =
				auth.state.user.id === singleStaff.id ? singleStaff.avatar_url : null;
		},
		SET_NEW_STAFF: (state, staff) => state.staffPaginate.push(staff),
		SEARCH_STAFF_BY_EMAIL: (state, staff) => (state.staffRole = staff),
		GET_NEW_HOD: (state, deptStaff) => (state.deptStaff = deptStaff),
		SEARCH_STAFF: (state, staff) => (state.authStaff = staff),
		SET_STAFF_LOGS: (state, staff) => (state.staffAuthLog = staff),
		SET_STAFF_LOGS_META: (state, meta) => (state.staffAuthLogMeta = meta),
		SET_ALL_POSITIONS: (state, positions) => (state.positions = positions),
		SET_ALL_CATEGORIES: (state, categories) => (state.categories = categories),
		SAVE_STAFF_CATEGORY: (state, category) => state.categories.push(category),
		DELETE_STAFF_CATEGORY: (state, category_id) =>
			(state.categories = state.categories.filter(
				(category) => category.id !== category_id
			)),
		SET_ALL_DIVISIONS: (state, divisions) => (state.divisions = divisions),
		SAVE_STAFF_POSITION: (state, position) => state.positions.push(position),
		DELETE_STAFF_POSITION: (state, position_id) =>
			(state.positions = state.positions.filter(
				(position) => position.id !== position_id
			)),
		EDIT_STAFF_POSITION: (state, position) => {
			const index = state.positions.findIndex(
				(item) => item.id === position.id
			);
			state.positions.splice(index, 1, position);
		},
		SAVE_STAFF_DIVISION: (state, division) => state.divisions.push(division),
		SAVE_CHANGES_DIVISION: (state, division) => {
			const index = state.divisions.findIndex(
				(item) => item.id === division.id
			);
			state.divisions.splice(index, 1, division);
		},
		DELETE_STAFF_DIVISION: (state, division_id) =>
			(state.divisions = state.divisions.filter(
				(division) => division.id !== division_id
			)),
		GET_LEAVE: (state, leave) => (state.leave = leave),
		SAVE_LEAVE: (state, leave) => state.leave.push(leave),
		STAFF_LEAVE_REQUEST: (state, leaveRequest) =>
			state.leaveRequest.push(leaveRequest),
		STAFF_APPROVED_LEAVE: (state, leave) => (state.approvedLeave = leave),
		STAFF_LEAVE: (state, leave) => (state.staffLeave = leave),
		STAFF_PENDING_LEAVE: (state, leave) => {
			state.pendingLeave = leave;
			state.pendingCount = [...leave].filter(
				(item) => item.status === "Pending"
			).length;

			if (state.pendingCount > 0)
				state.notifications = [
					{
						type: "leave",
						message: `${state.pendingCount} leave ${
							state.pendingCount > 1 ? "applications" : "application"
						}  awaiting`,
						link: "/leave_management/leaveapproval",
					},
				];
			else state.notifications = [];
		},
		STAFF_LEAVE_REQUEST_RESPONSE: (state, leave) => {
			state.pendingLeave.splice(
				state.pendingLeave.findIndex((item) => item.id === leave),
				1
			);
			state.pendingCount = [...state.pendingLeave].filter(
				(item) => item.status === "Pending"
			).length;

			if (state.pendingCount > 0)
				state.notifications = [
					{
						type: "leave",
						message: `${state.pendingCount} leave ${
							state.pendingCount > 1 ? "applications" : "application"
						}  awaiting`,
						link: "/leave_management/leaveapproval",
					},
				];
			else state.notifications = [];
		},
	},
	actions: {
		async getActiveStaff({ commit }) {
			await Api()
				.post("/getactivestaff")
				.then((response) => {
					commit("SET_ACTIVE_STAFF", response.data.data);
				});
		},

		async getStaff({ commit }, basket) {
			await Api()
				.get("/stafflist?page=" + basket.page + "&email=" + basket.email)
				.then((res) => {
					commit("SET_STAFF", res.data.data);
					commit("SET_STAFF_META", res.data.meta);
				});
		},

		async getSingleStaff({ commit }, staff_id) {
			await Api()
				.post("/getsinglestaff", { staff_id })
				.then((response) => {
					commit("SET_SINGLE_STAFF", response.data.data);
				});
		},

		async userQualification({ commit }, qualification) {
			await Api()
				.post("user_qualification", { qualification })
				.then((res) => {
					commit("SET_SINGLE_STAFF", res.data.data);
				});
		},

		async userGeneral({ commit }, general) {
			await Api()
				.post("user_general", { general })
				.then((res) => {
					commit("SET_SINGLE_STAFF", res.data.data);
				});
		},

		async userBank({ commit }, bank) {
			await Api()
				.post("user_bank", { bank })
				.then((res) => {
					commit("SET_SINGLE_STAFF", res.data.data);
				});
		},

		async userEmployment({ commit }, employment) {
			await Api()
				.post("user_employment", { employment })
				.then((res) => {
					commit("SET_SINGLE_STAFF", res.data.data);
				});
		},

		async userSpouse({ commit }, spouse) {
			await Api()
				.post("user_spouse", { spouse })
				.then((res) => {
					commit("SET_SINGLE_STAFF", res.data.data);
				});
		},

		async userSpouseChildren({ commit }, basket) {
			await Api()
				.post("spousechildren", { basket })
				.then((res) => {
					commit("SET_SINGLE_STAFF", res.data.data);
				});
		},

		async userChildren({ commit }, children) {
			await Api()
				.post("user_children", { children })
				.then((res) => {
					commit("SET_SINGLE_STAFF", res.data.data);
				});
		},

		async userParent({ commit }, parent) {
			await Api()
				.post("user_parent", { parent })
				.then((res) => {
					commit("SET_SINGLE_STAFF", res.data.data);
				});
		},

		async userKin({ commit }, kin) {
			await Api()
				.post("user_kin", { kin })
				.then((res) => {
					commit("SET_SINGLE_STAFF", res.data.data);
				});
		},

		async userGratuity({ commit }, gratuity) {
			await Api()
				.post("user_gratuity", { gratuity })
				.then((res) => {
					commit("SET_SINGLE_STAFF", res.data.data);
				});
		},

		async searchStaffByEmail({ commit }, basket) {
			await Api()
				.post("searchstaffbyemail", { basket })
				.then((res) => {
					commit("SEARCH_STAFF_BY_EMAIL", res.data.data);
				});
		},

		async getNewHoD({ commit }, basket) {
			await Api()
				.post("/getnewhod", { basket })
				.then((res) => {
					commit("GET_NEW_HOD", res.data.data);
				});
		},

		async searchStaffAuth({ commit }, basket) {
			await Api()
				.post("/searchstaff", { basket })
				.then((res) => {
					commit("SEARCH_STAFF", res.data.data);
				});
		},

		async getStaffAuthLogs({ commit }, basket) {
			await Api()
				.get("/authlogs?page=" + basket.page + "&staff=" + basket.staff)
				.then((res) => {
					commit("SET_STAFF_LOGS", res.data.data);
					commit("SET_STAFF_LOGS_META", res.data.meta);
				});
		},

		async getStaffPosition({ commit }) {
			await Api()
				.get("/positions")
				.then((res) => {
					commit("SET_ALL_POSITIONS", res.data.data);
				});
		},

		async getStaffCategory({ commit }) {
			await Api()
				.get("/categories")
				.then((res) => {
					commit("SET_ALL_CATEGORIES", res.data.data);
				});
		},

		async getStaffDivision({ commit }) {
			await Api()
				.get("/divisions")
				.then((res) => {
					commit("SET_ALL_DIVISIONS", res.data.data);
				});
		},

		async saveCategory({ commit }, basket) {
			await Api()
				.post("/savestaffcategory", { basket })
				.then((res) => commit("SAVE_STAFF_CATEGORY", res.data.data));
		},

		async saveDivision({ commit }, basket) {
			await Api()
				.post("/savestaffdivision", { basket })
				.then((res) => commit("SAVE_STAFF_DIVISION", res.data.data));
		},

		async savePosition({ commit }, basket) {
			await Api()
				.post("/savestaffposition", { basket })
				.then((res) => commit("SAVE_STAFF_POSITION", res.data.data));
		},

		async removeCategory({ commit }, id) {
			await Api()
				.delete("/deletecategory/" + id)
				.then(() => commit("DELETE_STAFF_CATEGORY", id));
		},

		async deletePosition({ commit }, id) {
			await Api()
				.delete("/deleteposition/" + id)
				.then(() => commit("DELETE_STAFF_POSITION", id));
		},

		async deleteDivision({ commit }, id) {
			await Api()
				.delete("/deletedivision/" + id)
				.then(() => commit("DELETE_STAFF_DIVISION", id));
		},

		async editPosition({ commit }, basket) {
			await Api()
				.post(`/editposition`, { basket })
				.then((res) => commit("EDIT_STAFF_POSITION", res.data.data));
		},

		async saveChangesDivision({ commit }, basket) {
			await Api()
				.post(`/savedivisionchanges`, { basket })
				.then((res) => commit("SAVE_CHANGES_DIVISION", res.data.data));
		},

		async saveLeave({ commit }, basket) {
			await Api()
				.post("/saveleave", { basket })
				.then((res) => commit("SAVE_LEAVE", res.data.data));
		},

		async getLeave({ commit }, basket) {
			await Api()
				.post("/getleave", { basket })
				.then((res) => commit("GET_LEAVE", res.data.data));
		},

		async staffLeaveRequest({ commit }, basket) {
			await Api()
				.post("saveleaverequest", { basket })
				.then((res) => commit("STAFF_LEAVE_REQUEST", res.data.data));
		},

		async staffApprovedLeave({ commit }, basket) {
			await Api()
				.post("staffapprovedleave", { basket })
				.then((res) => commit("STAFF_APPROVED_LEAVE", res.data.data));
		},

		async staffLeave({ commit }, basket) {
			await Api()
				.post("mystaffleave", { basket })
				.then((res) => commit("STAFF_LEAVE", res.data.data));
		},

		async staffPendingLeave({ commit }, { status }) {
			await Api()
				.post("getstaffleaveapprovalstatus", { status })
				.then((res) => commit("STAFF_PENDING_LEAVE", res.data.data));
		},

		async staffLeaveResponse({ commit }, { id, status, feedback_comment }) {
			await Api()
				.post("replyleaverequest", { id, status, feedback_comment })
				.then((res) => {
					commit("STAFF_LEAVE_REQUEST_RESPONSE", res.data);
				});
		},
	},
};
