import Api from "@/apis/Api";

export default {
  state: {
    intake: [],
    alreadyExist: {},
  },
  getters: {
    getters_intake: (state) => state.intake,
    getters_intakealreadyexist: (state) => state.alreadyExist,
  },
  mutations: {
    SET_INTAKE: (state, intake) => (state.intake = intake),
    SET_SAVE_INTAKE: (state, intakeBasket) => {
      state.intake.push(intakeBasket);
    },
    SET_ALREADYEXISTINTAKE: (state, alreadyExist) =>
      (state.alreadyExist = alreadyExist),

    REMOVE_INTAKE: (state, intake_id) => {
      state.intake.filter((intake, index) => {
        intake.id === intake_id ? state.intake.splice(index, 1) : null;
      });
    },
    SET_CURRENT_INTAKE: (state, intake_id) => {
      state.intake.forEach((intake) =>
        intake.current = (intake.id === intake_id) ? true : false
      )
    }
  },
  actions: {
    async getIntake({ commit }) {
      const response = await Api()
        .get("/intake")
        .then((res) => res.data);
      commit("SET_INTAKE", response.data);
    },
    async saveIntake({ commit }, intakeBasket) {
      await Api()
        .post("/saveintake", { intakeBasket })
        .then((res) => commit("SET_SAVE_INTAKE", res.data));
    },
    async removeIntake({ commit }, intake_id) {
      await Api()
        .delete(`/removeintake/${intake_id}`)
        .then(() => commit("REMOVE_INTAKE", intake_id));
    },

    async setCurrentIntake({ commit }, intake_id) {
      await Api()
        .put(`/setcurrentintake/${intake_id}`)
        .then(() => commit("SET_CURRENT_INTAKE", intake_id));
    }
  },
};
