import Api from "@/apis/Api";

export default {
	state: {
		finance: [],
		current_fees: {},
		balance: { debit: 0 },
		enrolledstudents: [],
		lateRegistration: [],
		enrolunenrol: []
	},
	getters: {
		getters_student_finance: (state) => state.finance,
		getters_current_fees: (state) => state.current_fees,
		getters_student_balance: (state) => state.balance,
		getters_enrolled_students: (state) => state.enrolledstudents,
		getters_late_registration: (state) => state.lateRegistration,
		getters_enrolunenrol: (state) => state.enrolunenrol
	},
	mutations: {
		SET_STUDENT_FINANCE: (state, finance) => (state.finance = finance),
		SET_CURRENT_FEES: (state, current_fees) =>
			(state.current_fees = current_fees),
		SET_STUDENT_BALANCE: (state, balance) => (state.balance = balance),
		SET_ENROLLED_STUDENTS: (state, basket) => (state.enrolledstudents = basket),
		LATE_REGISTRATION: (state, lateRegistration) => state.lateRegistration = lateRegistration,
		ENROLUNENROL: (state, enrolunenrol) => state.enrolunenrol = enrolunenrol
	},
	actions: {
		async getStudentFinance({ commit }, student_id) {
			const response = await Api()
				.post("/studentfinance", { student_id })
				.then((res) => res.data);
			commit("SET_STUDENT_FINANCE", response.data);
		},

		async getCurFees({ commit }, student) {
			const response = await Api()
				.post("/currentfees", { student })
				.then((res) => res.data);
			commit("SET_CURRENT_FEES", response.data);
		},

		async getStudentBalance({ commit }, student) {
			const response = await Api()
				.post("/studentbalance", { student })
				.then((res) => res.data);
			commit("SET_STUDENT_BALANCE", response.data);
		},

		async getEnrolledStudents({ commit }, basket) {
			await Api()
				.post("/enrolledstudents", { basket }, { responseType: "blob" })
				.then((res) => {
					const url = URL.createObjectURL(
						new Blob([res.data], {
							type: "application/vnd.ms-excel;charset=utf-8",
						})
					);

					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", "download.xlsx");
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					commit("SET_ENROLLED_STUDENTS", res.data);
				});
		},

		async lateRegistration({ commit }, student) {
			await Api()
				.post('/lateregistration', { student })
				.then((res) => commit('LATE_REGISTRATION', res.data.data))
		},

		async getEnrolledUnrolled({ commit }, student) {
			await Api()
				.post('/enrolunenrol', { student })
				.then((res) => commit('ENROLUNENROL', res.data.data))
		}
	},

};
