import Api from "@/apis/Api";

export default {
	state: {
		log: [],
	},
	getters: {
		getters_log: (state) => state.log,

	},
	mutations: {
		SET_ACTIVITY_LOG: (state, log) => (state.log = log),

	},
	actions: {
		async getActiviyLog({ commit }, basket) {
			const response = await Api()
				.post("/activitylog", { basket })
				.then((res) => res.data);
			commit("SET_ACTIVITY_LOG", response.data);
		},

	},
};
