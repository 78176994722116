/** @format */

import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import faculty from "./modules/faculty";
import department from "./modules/department";
import certificate from "./modules/certificate";
import deptprogram from "./modules/deptprogram";
import calendar from "./modules/calendar";
import semester from "./modules/semester";
import level from "./modules/level";
import intake from "./modules/intake";
import session from "./modules/session";
import fees from "./modules/fees";
import campus from "./modules/campus";
import program from "./modules/program";
import course from "./modules/course";
import mounted_course from "./modules/mounted_course";
import user from "./modules/user";
import student from "./modules/student";
import publishresults from "./modules/publishresults";
import finance from "./modules/finance";
import results from "./modules/results";
import permission from "./modules/permission";
import role from "./modules/role";
import home from "./modules/home";
import studentstatus from "./modules/studentstatus";
import misc from "./modules/misc";
import qualityassurance from './modules/qualityassurance'
import appraisal from './modules/appraisal'
import log from './modules/log'
import organogram from './modules/organogram'
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    faculty,
    department,
    certificate,
    deptprogram,
    calendar,
    semester,
    level,
    intake,
    session,
    fees,
    campus,
    program,
    course,
    mounted_course,
    user,
    student,
    publishresults,
    finance,
    results,
    permission,
    role,
    home,
    studentstatus,
    misc,
    qualityassurance,
    appraisal,
    log,
    organogram
  },
});
