import Api from "@/apis/Api";

export default {
	state: {
		total_student_campus: [],
	},
	getters: {
		getters_total_student_campus: (state) => state.total_student_campus,
	},
	mutations: {
		SET_TOTAL_STUDENT_CAMPUS: (state, campus) =>
			(state.total_student_campus = campus),
	},
	actions: {
		async totalstudentCampus({ commit }) {
			const response = await Api()
				.get("/generalinfodash")
				.then((res) => res.data);

			commit("SET_TOTAL_STUDENT_CAMPUS", response.data);
		},
	},
};
