import Api from "@/apis/Api";

export default {
	state: {
		campus: [],
	},
	getters: {
		getters_campus: (state) => state.campus,
	},
	mutations: {
		SET_CAMPUS: (state, campus) => (state.campus = campus),
		SET_SAVECAMPUS: (state, campusBasket) => {
			state.campus.push(campusBasket);
		},

		REMOVE_CAMPUS: (state, campus_id) => {
			state.campus.filter((campus, index) => {
				campus.id === campus_id ? state.campus.splice(index, 1) : null;
			});
		},
	},
	actions: {
		async getCampus({ commit }) {
			const response = await Api()
				.get("/campus")
				.then((res) => res.data);
			commit("SET_CAMPUS", response.data);
		},

		async saveCampus({ commit }, campusBasket) {
			await Api()
				.post("/savecampus", { campusBasket })
				.then((res) => commit("SET_SAVECAMPUS", res.data));
		},

		async removeCampus({ commit }, campus_id) {
			await Api()
				.delete(`/removecampus/${campus_id}`)
				.then(() => commit("REMOVE_CAMPUS", campus_id));
		},
	},
};
