import Api from "@/apis/Api";

export default {
  state: {
    project: [],
  },
  getters: {
    getters_project: (state) => state.project,
  },
  mutations: {
    SAVE_PROJECT: (state, project) => (state.project = project),

  },
  actions: {
    async saveProject({ commit }) {
      const response = await Api()
        .get("/saveproject")
        .then((res) => res.data);
      commit("SAVE_PROJECT", response.data);
    },
  },
};
