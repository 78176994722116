import Api from "@/apis/Api";

export default {
  state: {
    department: [],
    checkdepartmentavail: false,
  },
  getters: {
    getters_department: (state) => state.department,
    getters_checkdepartmentavail: (state) => state.checkdepartmentavail,
  },
  mutations: {
    SET_DEPARTMENT: (state, data) => (state.department = data),
    SET_CHECKDEPARTMENTAVAIL: (state, checkdepartmentavail) =>
      (state.checkdepartmentavail = checkdepartmentavail),

    SAVE_DEPARTMENT: (state, departmentBasket) => {
      state.department.push(departmentBasket);
      state.duplicate_department = null;
    },
    SAVE_EXISTS_DEPARTMENT: (state, department) =>
      (state.duplicate_department = department),
    REMOVE_DEPARTMENT: (state, department_id) => {
      state.department.filter((department, index) => {
        department.id === department_id
          ? state.department.splice(index, 1)
          : null;
      });
    },
    SAVE_NEW_HOD: (state, currDept) => {
      state.department.forEach((dept, index) => {
        if (dept.id === currDept.id && dept.faculty_id === currDept.faculty_id) {
          state.department.splice(index, 1, currDept)
        }
      }
      )
    }
  },
  actions: {
    async getDepartment({ commit }) {
      const response = await Api()
        .get("/all_department")
        .then((res) => res.data);
      commit("SET_DEPARTMENT", response.data);
    },

    async saveDepartment({ commit }, departmentBasket) {
      await Api()
        .post("/savedepartment", { departmentBasket })
        .then(() => commit("SAVE_DEPARTMENT", departmentBasket));
    },

    async checkDepartmentAvail({ commit }, department_id) {
      await Api()
        .post("/checkdepartmentavail", { department_id })
        .then((res) => {
          commit("SET_CHECKDEPARTMENTAVAIL", res.data);
        });
    },

    async removeDepartment({ commit }, department_id) {
      await Api()
        .delete(`/removedepartment/${department_id}`)
        .then((res) => res.data);
      commit("REMOVE_DEPARTMENT", department_id);
    },

    async saveNewHod({ commit }, basket) {
      await Api()
        .post("/savenewhod", { basket })
        .then((res) => {
          commit("SAVE_NEW_HOD", res.data.data);
        });
    }
  },
};
