import Api from "@/apis/Api";

export default {
  state: {
    semester: [],
    alreadyExist: {},
  },
  getters: {
    getters_semester: (state) => state.semester,
    getters_semesteralreadyexist: (state) => state.alreadyExist,
  },
  mutations: {
    SET_SEMESTER: (state, semester) => (state.semester = semester),
    SET_SAVESEMESTER: (state, semesterBasket) =>
      state.semester.push(semesterBasket),
    SET_ALREADYEXISTSEMESTER: (state, alreadyExist) =>
      (state.alreadyExist = alreadyExist),

    REMOVE_SEMESTER: (state, semester_id) => {
      state.semester.filter((semester, index) => {
        semester.id === semester_id ? state.semester.splice(index, 1) : null;
      });
    },

    SAVE_SEMESTER_CHANGES: (state, data) => {
      if (data.response === "updated") {
        state.semester.forEach((element) => {
          element.id === data.basket.id
            ? (element.semester = data.basket.newSemesterText)
            : null;
        });
      }
    },
  },
  actions: {
    async getSemester({ commit }) {
      const response = await Api()
        .get("/semester")
        .then((res) => res.data);
      commit("SET_SEMESTER", response.data);
    },

    async saveSemester({ commit }, semesterBasket) {
      await Api()
        .post("/savesemester", { semesterBasket })
        .then((res) => {
          commit("SET_SAVESEMESTER", res.data);
        });
    },

    async saveSemesterChanges({ commit }, semesterBasket) {
      await Api()
        .post("/savesemestertchanges", { semesterBasket })
        .then((res) => {
          let item = { basket: semesterBasket, response: res.data };
          commit("SAVE_SEMESTER_CHANGES", item);
        });
    },

    async removeSemester({ commit }, semester_id) {
      await Api()
        .delete(`/removesemester/${semester_id}`)
        .then((res) => {
          res.data === "deleted" ? commit("REMOVE_SEMESTER", semester_id) : "";
        });
    },
  },
};
