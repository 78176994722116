<template>
  <v-app color="grey lighten-4">
    <v-progress-linear indeterminate v-if="pgLoader"></v-progress-linear>
    <div>
      <v-scroll-y-transition mode="out-in" origin>
        <Navbar v-if="user" />
      </v-scroll-y-transition>
    </div>
    <v-main>
      <v-scroll-y-transition mode="out-in" origin="center center">
        <router-view :key="$route.params.slug"></router-view>
      </v-scroll-y-transition>

      <v-btn
        v-scroll="onScroll"
        v-show="fab"
        fab
        dark
        fixed
        bottom
        right
        color="primary"
        @click="toTop"
      >
        <v-icon>mdi-chevron-up</v-icon>
      </v-btn>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
  import Navbar from "@/components/Navbar";
  import Footer from "@/components/Footer";
  import { useGetters } from "vuex-composition-helpers";
  import { getCurrentInstance, onMounted, reactive, toRefs, watch } from "vue";
  import goTo from "vuetify/lib/services/goto";

  export default {
    name: "App",
    components: {
      Navbar,
      Footer,
    },
    setup() {
      const { authenticated, user } = useGetters(["authenticated", "user"]);

      const data = reactive({ fab: false, pgLoader: true });
      const { fab, pgLoader } = toRefs(data);

      const onScroll = (e) => {
        if (typeof window === "undefined") return;
        const top = window.pageYOffset || e.target.scrollTop || 0;
        fab.value = top > 20;
      };

      const toTop = () => goTo(0);

      const vm = getCurrentInstance();

      onMounted(() => (pgLoader.value = false));

      watch(
        () => vm.proxy.$route,
        () => {
          document.title = vm.proxy.$route.meta.title || "";
        }
      );

      return {
        user,
        authenticated,
        ...toRefs(data),
        onScroll,
        toTop,
      };
    },
  };
</script>

<style scoped>
  #app {
    background-color: #f5f5f5;
  }
</style>
<style>
  ::-webkit-scrollbar {
    width: 0;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent !important;
  }
  ::-webkit-scrollbar-thumb {
    background: #dbdbdb;
    border-radius: 100px !important;
    -webkit-border-radius: 100px !important;
    background-clip: padding-box;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(148, 148, 148);
  }

  .shadow {
    box-shadow: rgba(0, 0, 0, 0.66) 0px 25.6px 40px 0px,
      rgba(0, 0, 0, 0.54) 0px 4.8px 14.4px 0px;
  }
</style>

<style>
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
