import Api from "@/apis/Api";

export default {
	state: {
		deptprogram: [],
		checkdeptprogramavail: false,
		alreadyExist: "",
	},
	getters: {
		getters_deptprogram: (state) => state.deptprogram,
	},
	mutations: {
		SAVE_DEPTPROGRAM: (state, programBasket) => {
			state.deptprogram.push(programBasket);
		},
		SET_DEPTPROGRAM: (state, program) => (state.deptprogram = program),
		SAVE_NOTDEPTPROGRAMCHANGE: (state, programBasket) => {
			state.deptprogram.forEach((element) => {
				element.program_id === programBasket.program_id
					? (element.program_name = programBasket.program_name)
					: "";
			});
		},
		SAVE_DEPTPROGRAMCHANGE: (state, programBasket) => {
			state.deptprogram.forEach((element) => {
				element.deptprogram_id === programBasket.program_id
					? (element.program_name = programBasket.originalProgramText)
					: null;
			});
		},
		REMOVE_DEPTPROGRAM: (state, program_id) => {
			state.deptprogram.filter((program, index) => {
				program.id === program_id ? state.deptprogram.splice(index, 1) : null;
			});
		},
	},
	actions: {
		async getDeptProgram({ commit }, dept_id) {
			const response = await Api()
				.post("/deptprogram", { dept_id })
				.then((res) => res.data);
			commit("SET_DEPTPROGRAM", response.data);
		},
		async saveDeptProgram({ commit }, programBasket) {
			await Api()
				.post("/savedeptprogram", { programBasket })
				.then(() => commit("SAVE_DEPTPROGRAM", programBasket));
		},
		async saveDeptProgramChanges({ commit }, programBasket) {
			await Api()
				.post("/savedeptprogramchanges", { programBasket })
				.then((res) => {
					res.data === "updated"
						? commit("SAVE_DEPTPROGRAMCHANGE", programBasket)
						: commit("SAVE_NOTDEPTPROGRAMCHANGE", programBasket);
				});
		},

		async removeDeptProgram({ commit }, program_id) {
			await Api()
				.delete(`/removedeptprogram/${program_id}`)
				.then(() => commit("REMOVE_DEPTPROGRAM", program_id));
		},
	},
};
