import Api from "@/apis/Api";

export default {
  state: {
    questions: [],
    headers: [],
    assessment: [],
    analysis: []
  },
  getters: {
    getters_questions: (state) => state.questions,
    getters_headers: (state) => state.headers,
    getters_assessment: (state) => state.assessment,
    getters_analysis: (state) => state.analysis
  },
  mutations: {
    SET_SAVE_QUESTION: (state, question) => state.questions.push(question),
    SET_GETQUESTIONS: (state, questions) => state.questions = questions,
    SET_DELETEQUESTION: (state, question_id) => state.questions.filter((question, index) => {
      question.id === question_id ? state.questions.splice(index, 1) : null;
    }),
    SET_UPDATEQUESTION: (state, question) => state.questions.filter((item, index) => {
      item.id === question.id ? state.questions.splice(index, 1, question) : null;
    }),
    GET_HEADERS: (state, headers) => state.headers = headers,
    SET_EVALUATION_DATES: (state, header) => {
      state.headers.filter((item) => {
        if (item.id === header.id) {
          item.evaluation_start = header.evaluation_start
          item.evaluation_end = header.evaluation_end
        }
      })
    },
    GET_LECTURER_ASSESSMENT: (state, assessment) => state.assessment = assessment,
    GET_ANALYSIS_DATA: (state, analysis) => state.analysis = analysis,
    SET_EXTENSION_DATES: (state, selected_header) =>
      state.questions.filter((header, index) =>
        header.id === selected_header.id ? state.headers.splice(index, 1) : null
      )

  },

  actions: {
    async getQuestions({ commit }) {
      await Api()
        .get('/getquestions')
        .then((res) => commit('SET_GETQUESTIONS', res.data.data))
    },

    async getHeaders({ commit }) {
      await Api()
        .post('/getheaders')
        .then((res) => commit('GET_HEADERS', res.data.data))
    },

    async setElevationDates({ commit }, header) {
      await Api()
        .post("/setevaluation", { header })
        .then(() => commit("SET_EVALUATION_DATES", header));
    },

    async saveQuestion({ commit }, bank) {
      await Api()
        .post("/savequestion", { bank })
        .then((res) => commit("SET_SAVE_QUESTION", res.data.data));
    },

    async updateQuestion({ commit }, bank) {
      await Api()
        .post("/updatequestion", { bank })
        .then(() => commit("SET_UPDATEQUESTION", bank));
    },

    async deleteQuestion({ commit }, question_id) {
      await Api()
        .delete(`/deletequestion/${question_id}`)
        .then(() => commit("SET_DELETEQUESTION", question_id));
    },

    async getLecturerAssessment({ commit }, basket) {
      await Api()
        .post("/getlecturerassessment", { basket })
        .then((res) => commit("GET_LECTURER_ASSESSMENT", res.data.data));
    },

    async getAnalysisData({ commit }, basket) {
      await Api()
        .post("/getanalysisdata", { basket })
        .then((res) => commit("GET_ANALYSIS_DATA", res.data.data));
    },

    async editSelectedExtensionDates({ commit }, basket) {
      await Api()
        .post("/editselectedexteniondates", { basket })
        .then((res) => commit("SET_EXTENSION_DATES", res.data.data));
    },

  },
};
