import Api from "@/apis/Api";

export default {
  state: {
    mounted_courses: [],
    header_exists: null,
    header_course_campus: [],
    header_session_lecturer: [],
  },
  getters: {
    getters_mounted_courses: (state) => state.mounted_courses,
    getters_header_exists: (state) => state.header_exists,
    getters_course_header_campus: (state) => state.header_course_campus,
    getters_header_session_lecturer: (state) => state.header_session_lecturer,
  },
  mutations: {
    SET_MOUNTED_COURSES: (state, mounted_courses) =>
      (state.mounted_courses = mounted_courses),
    SET_HEADER_EXISTS: (state, header_exists) => {
      state.header_exists = header_exists;
    },
    SET_COURSE_HEADER_CAMPUS: (state, header_course_campus) => {
      state.header_course_campus = header_course_campus;
    },
    SET_ASSIGNED_LECTURER: (state, header_course_campus) => {
      state.header_course_campus = header_course_campus;
    },
    SET_EDIT_ASSIGNED_LECTURER: (state, header) => {
      state.header_session_lecturer = header;
    },
  },
  actions: {
    async saveMountedCourses({ commit }, mounted_courses_basket) {
      const response = await Api()
        .post("/savemountedcourses", { mounted_courses_basket })
        .then((res) => res.data);
      commit("SET_MOUNTED_COURSES", response.data);
    },

    async ifheaderExists({ commit }, header) {
      const response = await Api()
        .post("/ifheaderexists", { header })
        .then((res) => res.data);
      commit("SET_HEADER_EXISTS", response);
    },

    async getcourseheaderCampus({ commit }, header) {
      const response = await Api()
        .post("/getcourseheadercampus", { header })
        .then((res) => res.data);
      commit("SET_COURSE_HEADER_CAMPUS", response.data);
    },

    async saveassgingedLecturer({ commit }, header) {
      const response = await Api()
        .post("/saveassignedlecturer", { header })
        .then((res) => res.data);
      commit("SET_ASSIGNED_LECTURER", response.data);
    },

    async editassgingedLecturer({ commit }, header) {
      const response = await Api()
        .post("/editassignedlecturer", { header })
        .then((res) => res.data);
      commit("SET_EDIT_ASSIGNED_LECTURER", response.data);
    },
  },
};
