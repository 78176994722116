import Api from "@/apis/Api";
import auth from "./auth";


export default {
  state: {
    student: {},
    student_campus: [],
    enrol_student: {
      /* is_valid: false */
    },
    courselecturercurrcalendar: [],
    scripts: [],
    check_student_balance: 0,
    student_resit: [],
    assign_resit: [],
    resit_basket: [],
    resit_script: [],
    retake_basket: [],
    indexnumber: "",
    studentstatuslog: [],
    students: [],
    probation: [],
    lecturerupload: [],
    student_current_header: [],
    current_headers: [],
    current_headers_meta: [],
    document: [],
    student_sch_info: []
  },
  getters: {
    getters_all_students: (state) => state.students,
    getters_scripts: (state) => state.scripts,
    getters_saved_student: (state) => state.student,
    getters_student_campus: (state) => state.student_campus,
    getters_student_details: (state) => state.student,
    getters_enrol_student: (state) => state.enrol_student,
    getters_courselecturercurrcalendar: (state) =>
      state.courselecturercurrcalendar,
    getters_checkstudent_balance: (state) => state.check_student_balance,
    getters_student_resit: (state) => state.student_resit,
    getters_assign_resit: (state) => state.assign_resit,
    getters_lecturer_course_resit: (state) => state.resit_basket,
    getters_lecturer_course_retake: (state) => state.retake_basket,
    getters_index_number: (state) => state.indexnumber,
    getters_student_status_log: (state) => state.studentstatuslog,
    getters_probation: (state) => state.probation,
    getters_lecturer_upload: (state) => state.lecturerupload,
    getters_student_current_header: (state) => state.student_current_header,
    getters_current_headers: (state) => state.current_headers,
    getters_current_headers_meta: (state) => state.current_headers_meta,
    getters_document: (state) => state.document,
  },
  mutations: {
    // SET_STUDENT_STATUS: (state, data) => (state.all_student_status = data),
    SET_SCRIPTS: (state, scripts) => (state.scripts = scripts),
    SAVE_STUDENT: (state, student) => (state.student = student),
    SET_STUDENT_CAMPUS: (state, student_campus) =>
      (state.student_campus = student_campus),
    SET_STUDENT_DETAILS: (state, student) => (state.student = student),
    SET_ENROL_STUDENT: (state, enrol_student) =>
      (state.enrol_student = enrol_student),
    SET_COURSELECTURERCURRENTCALENDAR: (state, courselecturercurrcalendar) =>
      (state.courselecturercurrcalendar = courselecturercurrcalendar),
    SET_CHECK_STUDENT_BALANCE: (state, balance) =>
      (state.check_student_balance = balance),
    SET_STUDENT_RESIT: (state, resit) => (state.student_resit = resit),
    SET_ASSIGN_STUDENT_RESIT: (state, assign_resit) =>
      (state.assign_resit = assign_resit),
    SET_COURSE_LECTURER_RESIT: (state, basket) => {
      let infors = [];
      basket.forEach((d) => {
        let info = {
          program_id: d.header.program_id,
          course_name: d.course.coursecode_coursename,
          program_name: d.header.program.program_name_certificate,
          semester: d.header.semester,
          level: d.header.level,
          id: [d.id],
          grade_id: d.header.grade_id,
          course_id: d.course_id,
          user_id: auth.state.user.id
        };
        updateInfo(info);
      });

      function updateInfo(j) {
        let index = infors.findIndex((i) =>
          i.course_id === j.course_id && i.program_id === j.program_id
        );

        index > -1 ? infors[index].id.push(j.id[0]) : infors.push(j);
      }

      state.resit_basket = infors;
    },
    SET_COURSE_LECTURER_RETAKE: (state, basket) => {
      let infors = [];

      basket.forEach((d) => {
        let info = {
          course_id: d.header_campus_course.course_id,
          program_id: d.program_id,
          course_name: d.header_campus_course.courses[0].coursecode_coursename,
          program_name: d.program.program_name_certificate,
          semester: d.semester.semester,
          semester_id: d.semester_id,
          campus_id: d.header_campus_course.header_campus.campus_id,
          campus_name: d.header_campus_course.header_campus.campus[0].campus_name,
          id: [d.id],
          grade_id: d.grade_id,
          session_id: d.session_id,
          session: d.session.session,
          level_id: d.level_id,
          hccs_id: d.hccs_id,
        };
        updateInfo(info);
      });

      function updateInfo(j) {
        let index = infors.findIndex((i) => {
          return i.course_id === j.course_id && i.program_id === j.program_id;
        });

        index > -1 ? infors[index].id.push(j.id[0]) : infors.push(j);
      }

      state.retake_basket = infors;
    },
    SET_RESIT_SCRIPTS: (state, basket) => (state.resit_script = basket),
    SET_SEARCH_INDEX_NUMBER: (state, indexnumber) =>
      (state.indexnumber = indexnumber),
    SET_STUDENT_STATUS_LOG: (state, data) => (state.studentstatuslog = data),
    SET_INSERT_NEW_STUDENT_STATUS: (state, data) =>
      state.studentstatuslog.push(data),
    DELETE_STUDENT: (state, student_id) => {
      state.students.filter((students, index) => {
        students.id === student_id ? state.students.splice(index, 1) : null;
      });
    },
    SET_PROBATION: (state, data) => (state.probation = data),
    SET_LECTURER_UPLOAD: (state, data) => (state.lecturerupload = data),
    STUDENT_CURRENT_HEADER: (state, data) =>
      (state.student_current_header = data),

    SET_CURRENT_HEADERS: (state, data) => (state.current_headers = data),
    SET_CURRENT_HEADERS_META: (state, data) => (state.current_headers_meta = data),
    HEADER_STATUS_CHANGES: (state, result) => {
      state.current_headers.forEach((row) => {
        result.forEach((item) =>
          row.id === item.id ? (row.status = item.status) : null
        );
      });
    },
    SET_PUBLISH_RESULT: (state, result) => {
      for (const level in state.lecturerupload) {
        state.lecturerupload[level].forEach((row) =>
          result.forEach((item) =>
            row.id === item.id ? (row.is_published = !item.is_published) : null
          )
        );
      }
    },

    UNPUBLISH_RESULT: (state, result) => {
      for (const level in state.lecturerupload) {
        state.lecturerupload[level].forEach((row) =>
          result.forEach((item) =>
            row.id === item.id ? (row.is_published = item.is_published) : null
          )
        );
      }
    },
    USER_DOCUMENT: (state, document) => state.document = document,
  },

  actions: {
    async saveStudent({ commit }, studentBasket) {
      await Api()
        .post("/savestudent", { studentBasket })
        .then((res) => {
          commit("SAVE_STUDENT", res.data.data);
        });
    },

    async getStudentCampus({ commit }, studentBasket) {
      const response = await Api()
        .post("/getstudentcampus", { studentBasket })
        .then((res) => res.data);
      commit("SET_STUDENT_CAMPUS", response.data);
    },

    async getStudentDetails({ commit }, index_number) {
      const response = await Api()
        .post("/getstudentdetails", { index_number })
        .then((res) => res.data);
      commit("SET_STUDENT_DETAILS", response.data);
    },

    async enrolStudent({ commit }, studentBasket) {
      const response = await Api()
        .post("/enrolstudent", { studentBasket })
        .then((res) => res.data);
      commit("SET_ENROL_STUDENT", response.data);
    },

    async getCourseLecturerCurrentCalendar({ commit }, user) {
      const response = await Api()
        .post("/courselecturercurrentcalendar", { user })
        .then((res) => res.data);
      commit("SET_COURSELECTURERCURRENTCALENDAR", response.data);
    },

    async getScripts({ commit }, scriptBasket) {
      await Api()
        .post("scripts", { basket: scriptBasket }, { responseType: "blob" })
        .then((res) => {
          let fileName =
            scriptBasket?.course?.course_name + "_" +
            scriptBasket?.campus?.campus_name + "_" +
            scriptBasket?.calendar?.startyear + "_" +
            scriptBasket?.calendar?.endyear + "_" +
            scriptBasket?.level?.level + "_" +
            scriptBasket?.semester?.semester + "_" +
            scriptBasket?.program?.program_name_certificate;
          const url = URL.createObjectURL(
            new Blob([res.data], {
              type: "application/vnd.ms-excel;charset=utf-8",
            })
          );

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName + ".xlsx");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          commit("SET_SCRIPTS", res.data);
        });
    },

    async downloadBroadSheet({ commit }, scriptBasket) {
      await Api()
        .post(
          "downloadbroadsheet",
          { basket: scriptBasket },
          { responseType: "blob" }
        )
        .then((res) => {
          let fileName = "fileName";
          const url = URL.createObjectURL(
            new Blob([res.data], {
              type: "application/vnd.ms-excel;charset=utf-8",
            })
          );

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName + ".xlsx");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          commit("SET_SCRIPTS", res.data);
        });
    },

    async broadSheet({ commit }, scriptBasket) {
      await Api()
        .post(
          "/broadsheet",
          { basket: scriptBasket },
          { responseType: "blob" }
        )
        .then((res) => {
          let fileName = "fileName";
          const url = URL.createObjectURL(
            new Blob([res.data], {
              type: "application/vnd.ms-excel;charset=utf-8",
            })
          );

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName + ".xlsx");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          commit("SET_SCRIPTS", res.data);
        });
    },

    async getCheckStudentBalance({ commit }, student) {
      const response = await Api()
        .post("/studentbalance", { student })
        .then((res) => res.data);
      commit("SET_CHECK_STUDENT_BALANCE", response.data);
    },

    async getStudentResit({ commit }, student) {
      const response = await Api()
        .post("/getstudentresit", { student })
        .then((res) => res.data);
      commit("SET_STUDENT_RESIT", response.data);
    },

    async assignStudentResit({ commit }, student) {
      const response = await Api()
        .post("/assignstudentresit", { student })
        .then((res) => res.data);
      commit("SET_ASSIGN_STUDENT_RESIT", response.data);
    },

    async courseLecturerResit({ commit }, user_id) {
      const response = await Api()
        .post("/courselecturerresit", { user_id })
        .then((res) => res.data);
      commit("SET_COURSE_LECTURER_RESIT", response.data);
    },

    async courseLecturerRetake({ commit }, user_id) {
      const response = await Api()
        .post("/courselecturerretake", { user_id })
        .then((res) => res.data);
      commit("SET_COURSE_LECTURER_RETAKE", response.data);
    },

    async getresitSheet({ commit }, basket) {
      await Api()
        .post("/resitsheet", { basket }, { responseType: "blob" })
        .then((res) => {
          let fileName =
            basket.course_name + "_" + basket.program_name + "_RESIT";
          const url = URL.createObjectURL(
            new Blob([res.data], {
              type: "application/vnd.ms-excel;charset=utf-8",
            })
          );

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName + ".xlsx");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          commit("SET_RESIT_SCRIPTS", res.data);
        });
    },

    async getretakeSheet({ commit }, basket) {
      await Api()
        .post("/retakesheet", { basket }, { responseType: "blob" })
        .then((res) => {
          let fileName =
            basket.course_name + "_" + basket.program_name + "_RETAKE";
          const url = URL.createObjectURL(
            new Blob([res.data], {
              type: "application/vnd.ms-excel;charset=utf-8",
            })
          );

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName + ".xlsx");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          commit("SET_RESIT_SCRIPTS", res.data);

        });
    },

    async searchindexNumber({ commit }, index_number) {
      const response = await Api()
        .post("/searchindexnumber", { index_number })
        .then((res) => res.data);
      commit("SET_SEARCH_INDEX_NUMBER", response.data);
    },

    async getstudentStatusLog({ commit }, id) {
      const response = await Api()
        .post("/getstudentstatuslog", { id })
        .then((res) => res.data);
      commit("SET_STUDENT_STATUS_LOG", response.data);
    },

    async insertnewStatus({ commit }, statusBasket) {
      const response = await Api()
        .post("/insertnewstudentstatus", { statusBasket })
        .then((res) => res.data);
      commit("SET_INSERT_NEW_STUDENT_STATUS", response.data);
    },

    async updateStudentRecord({ commit }, studentBasket) {
      await Api()
        .post("/updatestudentrecord", { studentBasket })
        .then((res) => commit("SAVE_STUDENT", res.data));
    },

    async deleteStudentRecord({ commit }, student_id) {
      await Api()
        .delete(`/deletestudent/${student_id}`)
        .then((result) => result.data);
      commit("DELETE_STUDENT", student_id);
    },

    async getProbationStudent({ commit }) {
      await Api()
        .get("/probation")
        .then((res) => commit("SET_PROBATION", res.data.data));
    },

    async getLecturerUpload({ commit }, header) {
      await Api()
        .post("/lecturerupload", { header })
        .then((res) => commit("SET_LECTURER_UPLOAD", res.data));
    },

    async getStudentCurrentHeader({ commit }, student_id) {
      const response = await Api()
        .post("/studentcurrentheader", { student_id })
        .then((res) => res.data);
      commit("STUDENT_CURRENT_HEADER", response.data);
    },

    async getCurrentHeaders({ commit }, page_number) {
      page_number = page_number || 1;

      const response = await Api()
        .get("/getcurrentheaders?page=" + page_number)
        .then((res) => res.data);
      commit("SET_CURRENT_HEADERS", response.data);
      commit("SET_CURRENT_HEADERS_META", response.meta);
    },

    async headerStatusChanges({ commit }, status) {
      await Api()
        .post("/headerstatuschanges", { status })
        .then(() => commit("HEADER_STATUS_CHANGES", status));
    },

    async publishResult({ commit }, id) {
      await Api()
        .post("/publishresult", { id })
        .then(() => commit("SET_PUBLISH_RESULT", id));
    },

    async unpublishResult({ commit }, id) {
      await Api()
        .post("/unpublishresult", { id })
        .then(() => commit("UNPUBLISH_RESULT", id));
    },

    async getDocument({ commit }, id) {
      await Api()
        .post("/getdocument", { id })
        .then((res) => commit("USER_DOCUMENT", res.data.data));
    },
  },
};
