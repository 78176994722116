/** @format */

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./pluggins/vuetify";
import axios from "axios";
import store from "./store";
import { setInteractionMode } from "vee-validate";

setInteractionMode("eager");

/* Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver); */

/* import { abilitiesPlugin } from "@casl/vue";
import ability from "./services/ability"; */
import moment from "moment";
// import VueCompositionAPI from "vue";
axios.defaults.baseURL = "http://localhost:8000/api";

import UUID from "vue-uuid";

Vue.use(UUID);

// Vue.use(VueCompositionAPI );

Vue.prototype.$http = axios;
Vue.config.productionTip = false;

Vue.prototype.moment = moment;
//import Pusher from "pusher-js";
//Pusher.logToConsole = true;

//import Echo from "laravel-echo";

/* window.Echo = new Echo({
  broadcaster: "pusher",
  key: "EZFd5wMSdJJxzbZmNyidAk9qfx7oK0mdQ21VpI3fw1wt9WyypE97wjxZZrde",
  cluster: "mt1",
  encrypted: true,
  authEndpoint: "http://localhost:8000/public/api/broadcasting/auth",
  auth: {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  },
  //websocket
  wsHost: window.location.hostname,
  wsPort: 6001,
  wssPort: 6001,
  enableTransports: ["ws", "flash", "wss"],
  forceTLS: false,
}); */

import Chartkick from "vue-chartkick";
import Chart from "chart.js";
// import "./assets/custom.css";
import VueHtmlToPaper from "vue-html-to-paper";
const options = {
	specs: ["fullscreen=yes", "titlebar=no", "scrollbars=no"],
	styles: [
		"https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
		"https://unpkg.com/kidlat-css/css/kidlat.css",
		"https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css",
	],
	timeout: 1000, // default timeout before the print window appears
	autoClose: true, // if false, the window will not close after printing
	windowTitle: window.document.title, // override the window title
};
Vue.use(VueHtmlToPaper, options);
Vue.use(Chartkick.use(Chart));

store.dispatch("me").then(() => {
	new Vue({
		router,
		vuetify,
		store,
		render: (h) => h(App),
	}).$mount("#app");
});
