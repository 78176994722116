import Api from "@/apis/Api";

export default {
	state: {
		fees: [],
		alreadyExist: [],
		misc_fees: [],
		misc_error: "",
		meta: [],
	},
	getters: {
		getters_fees: (state) => state.fees,
		getters_fees_meta: (state) => state.meta,
		getters_feesalreadyexist: (state) => state.alreadyExist,
		getters_misc_fees: (state) => state.misc_fees,
	},
	mutations: {
		SET_FEES: (state, fees) => (state.fees = fees),
		SET_SAVEFEES_META: (state, fees) => (state.meta = fees),
		SET_SAVEFEES: (state, fees) => (state.fees = fees),
		SET_ALREADYEXISTFEES: (state, alreadyExist) =>
			(state.alreadyExist = alreadyExist),
		REMOVE_FEES: (state, fees_id) => {
			state.fees.filter((fees, index) => {
				fees.id === fees_id ? state.fees.splice(index, 1) : null;
			});
		},
		SET_MISC_FEES: (state, fees) => (state.misc_fees = fees),
		SAVE_NEW_MISC: (state, misc_fees) => {
			state.misc_fees.push(misc_fees);
		},
		SAVE_MISC_AMOUNT_CHANGES: (state, data) => {
			let index = state.misc_fees.findIndex((obj) => obj.id === data.basket.id);
			state.misc_fees[index].amount = parseFloat(
				data.basket.originalAmountText
			).toFixed(2);
		},
		SAVE_FEES_CHANGES: (state, data) => {
			let index = state.fees.findIndex((obj) => obj.id === data.id);

			state.fees[index].continuing_student_fees_local =
				data.itemfees.edited_continuing_student_fees_local;
			state.fees[index].continuing_student_fees_foreign =
				data.itemfees.edited_continuing_student_fees_foreign;
			state.fees[index].fresh_student_fees_foreign =
				data.itemfees.edited_fresh_student_fees_foreign;
			state.fees[index].fresh_student_fees_local =
				data.itemfees.edited_fresh_student_fees_local;
		},
		REMOVE_MISCTYPE: (state, misc_id) => {
			state.misc_fees.filter((misc, index) => {
				misc.id === misc_id ? state.misc_fees.splice(index, 1) : null;
			});
		},
	},
	actions: {
		async getFees({ commit }) {
			const response = await Api()
				.get("/fees")
				.then((res) => res.data);
			commit("SET_SAVEFEES_META", response.meta);
			commit("SET_FEES", response.data);
		},

		async saveFees({ commit }, feesBasket) {
			const response = await Api()
				.post("/savefees", { feesBasket })
				.then((res) => res.data);
			commit("SET_SAVEFEES", response.data);
		},

		async removeFees({ commit }, fees_id) {
			await Api()
				.delete(`/removefees/${fees_id}`)
				.then(() => commit("REMOVE_FEES", fees_id));
		},

		async duplicateFees({ commit }, basket) {
			const response = await Api()
				.post("/ifduplicatefees", { basket })
				.then((res) => res.data);
			commit("SET_ALREADYEXISTFEES", response.data);
		},

		async getMiscFees({ commit }) {
			const response = await Api()
				.get("/miscellaneousfees")
				.then((res) => res.data);
			commit("SET_MISC_FEES", response.data);
		},

		async saveMiscAmountChanges({ commit }, feesBasket) {
			await Api()
				.post("/savemiscamountchanges", { feesBasket })
				.then((res) => {
					let item = { basket: feesBasket, response: res.data };
					commit("SAVE_MISC_AMOUNT_CHANGES", item);
				});
		},

		async removeMiscType({ commit }, misc_id) {
			await Api()
				.delete(`/removemisctype/${misc_id}`)
				.then(() => commit("REMOVE_MISCTYPE", misc_id));
		},

		async saveNewMisc({ commit }, feesBasket) {
			await Api()
				.post("/savenewmisc", { feesBasket })
				.then((res) => {
					commit("SAVE_NEW_MISC", res.data);
				});
		},

		async saveFeesChanges({ commit }, feesBasket) {
			await Api()
				.post("/savefeeschanges", { feesBasket })
				.then(() => {
					commit("SAVE_FEES_CHANGES", feesBasket);
				});
		},
	},
};
