import Api from "@/apis/Api";

export default {
	state: {
		studentstatus: [],
	},
	getters: {
		getters_student_status: (state) => state.studentstatus,
	},
	mutations: {
		SET_STUDENT_STATUS: (state, studentstatus) =>
			(state.studentstatus = studentstatus),
		SAVE_STUDENT_STATUS: (state, studentstatusBasket) => {
			state.studentstatus.push(studentstatusBasket);
		},

		REMOVE_STUDENTSTATUS: (state, studentstatus_id) => {
			state.studentstatus.filter((studentstatus, index) => {
				studentstatus.id === studentstatus_id
					? state.studentstatus.splice(index, 1)
					: null;
			});
		},
	},
	actions: {
		async getStudentStatus({ commit }) {
			const response = await Api()
				.get("/getstudentstatus")
				.then((res) => res.data);
			commit("SET_STUDENT_STATUS", response.data);
		},

		async saveStudentStatus({ commit }, studentstatusBasket) {
			await Api()
				.post("/savestudentstatus", { studentstatusBasket })
				.then((res) => commit("SAVE_STUDENT_STATUS", res.data));
		},

		async removeStudentStatus({ commit }, studentstatus_id) {
			await Api()
				.delete(`/removestudentstatus/${studentstatus_id}`)
				.then(() => commit("REMOVE_STUDENTSTATUS", studentstatus_id));
		},
	},
};
