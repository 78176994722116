import Api from "@/apis/Api";

export default {
  state: {
    courses: [],
    intakelevelsemester: [],
    meta: {},
    courseUploadMeta: {},
    courseUploadPaginate: [],
    uploadScript: [],
    scriptUploadMeta: [],
    requestedGrade: {},
  },
  getters: {
    getters_courses: (state) => state.courses,
    getters_savechangescourses: (state) => state.courses,
    getters_intakelevelsemester: (state) => state.intakelevelsemester,
    getters_courses_meta: (state) => state.meta,
    getters_course_uploads_meta: (state) => state.courseUploadMeta,
    getters_course_uploads_paginate: (state) => state.courseUploadPaginate,
    getters_upload_script: (state) => state.uploadScript,
    getters_script_upload_meta: (state) => state.scriptUploadMeta,
    getters_requested_grade: (state) => state.requestedGrade,
  },
  mutations: {
    SET_COURSES_META: (state, data) => (state.meta = data),
    SET_SAVECOURSE: (state, courses) => (state.courses = courses),
    SET_COURSES: (state, courses) => (state.courses = courses),
    SET_SAVECHANGECOURSE: (state, courseBasket) => {
      state.courses.forEach((course, index) => {
        courseBasket.forEach((newCourse) => {

          if (course.id === newCourse.id) {
            state.courses.splice(index, 1, newCourse);
          }
        });
      });
    },
    SET_INTAKELEVELSEMESTER: (state, data) =>
      (state.intakelevelsemester = data),
    SET_PAGINATE_COURSE_UPLOAD: (state, courses) =>
      (state.courseUploadPaginate = courses),
    SET_COURSE_UPLOAD_META: (state, meta) => (state.courseUploadMeta = meta),
    SET_SCRIPT_UPLOAD: (state, uploadScript) =>
      (state.uploadScript = uploadScript),
    SET_SCRIPT_UPLOAD_META: (state, meta) => (state.scriptUploadMeta = meta),
    SET_REQUESTED_GRADE: (state, grade) => (state.requestedGrade = grade),
  },
  actions: {
    async getCourses({ commit }, page_number) {
      page_number = page_number || 1;
      const response = await Api()
        .get("/courses?page=" + page_number)
        .then((res) => res.data);
      commit("SET_COURSES", response.data);
      commit("SET_COURSES_META", response.meta);
    },

    async saveCourse({ commit }, courseBasket) {
      const response = await Api()
        .post("/savecourse", { courseBasket })
        .then((res) => res.data);
      commit("SET_SAVECOURSE", response.data);
    },

    async saveChangesCourse({ commit }, courseBasket) {
      await Api()
        .post("/savecoursechanges", { courseBasket })
        .then((res) => {
          commit(
            "SET_SAVECHANGECOURSE",
            res.data.data /* [res.data, courseBasket] */
          );
        });
    },

    async intakeLevelSemester({ commit }) {
      const response = await Api()
        .get("/getstudentcourseregistration")
        .then((res) => res.data);
      commit("SET_INTAKELEVELSEMESTER", response.data);
    },

    async courseUpload({ commit }, { page, id }) {
      const response = await Api()
        .post("/lecturer_course_uploads?page=" + page, { id })
        .then((res) => res.data);
      commit("SET_PAGINATE_COURSE_UPLOAD", response.data);
      commit("SET_COURSE_UPLOAD_META", response.meta);
    },

    async getScriptUpload({ commit }, { page, id }) {
      const response = await Api()
        .post("/scriptupload?page=" + parseInt(page), { id })
        .then((res) => res.data);
      commit("SET_SCRIPT_UPLOAD", response.data);
      commit("SET_SCRIPT_UPLOAD_META", response.meta);
    },

    async getRequestedGrade({ commit }, id) {
      await Api()
        .post("/getrequestedgrade", { id })
        .then((res) => {
          commit("SET_REQUESTED_GRADE", res.data.data);
        });
    },


  },
};
