import Api from "@/apis/Api";

export default {
	state: {
		certificate: [],
		curr_certificate: null,
		error_certificate: "",
		remove_certificate: "",
	},
	getters: {
		getters_certificate: (state) => state.certificate,
		getters_currcertificate: (state) => state.curr_certificate,
		getters_error_certificate: (state) => state.error_certificate,
		getters_remove_certificate: (state) => state.remove_certificate,
	},
	mutations: {
		SET_CERTIFICATE: (state, certificate) => (state.certificate = certificate),
		SAVE_ERROR_CERTIFICATE: (state, certificate) =>
			(state.error_certificate = certificate),
		CURR_CERTIFICATE: (state, curr_certificate) =>
			(state.curr_certificate = curr_certificate),
		SAVE_CERTIFICATE: (state, certificateBasket) => {
			state.certificate.push(certificateBasket);
			state.error_certificate = true;
		},
		REMOVE_CERTIFICATE: (state, shorthand) => {
			state.certificate.forEach((element, index) => {
				element.certificate_shorthand === shorthand
					? state.certificate.splice(index, 1)
					: null;
			});
		},
	},
	actions: {
		async getCertificate({ commit }) {
			const response = await Api()
				.get("/certificate")
				.then((res) => res.data);
			commit("SET_CERTIFICATE", response.data);
		},

		async saveCertificate({ commit }, certBasket) {
			await Api()
				.post("/savecertificate", { certBasket })
				.then((res) => {
					commit("SAVE_CERTIFICATE", res.data);
				});
		},

		async getCurrentCertificate({ commit }, curr_certificate) {
			await Api()
				.post("/getcurrcertificate", { curr_certificate })
				.then((res) => {
					commit("CURR_CERTIFICATE", res.data);
				});
		},

		async removeCertificate({ commit }, shorthand) {
			await Api()
				.delete(`/removecertificate/${shorthand}`)
				.then((res) => {
					res.data === "deleted" ? commit("REMOVE_CERTIFICATE", shorthand) : "";
				});
		},
	},
};
