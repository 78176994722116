<template>
  <nav>
    <v-app-bar v-if="user" flat app>
      <v-app-bar-nav-icon class="grey--text" @click="drawer = !drawer" />

      <v-toolbar-title class="text-uppercase grey--text">
        <span class="font-weight-light">SCHOOL </span>
        <strong>Management System</strong>
      </v-toolbar-title>
      <v-spacer />
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-badge
            :content="getters_staff_notification.length"
            class="mr-1 mt-1"
            :value="getters_staff_notification.length"
            :color="getters_staff_notification.length > 0 ? 'error' : 'primary'"
            offset-x="20"
            overlap
            :class="
              getters_staff_notification.length > 0 ? 'constant-tilt-shake' : ''
            "
          >
            <v-btn v-bind="attrs" v-on="on" icon
              ><v-icon> mdi-message-outline</v-icon></v-btn
            >
          </v-badge>
        </template>

        <v-list nav v-if="getters_staff_notification.length > 0">
          <template v-for="(item, index) in getters_staff_notification">
            <v-list-item
              router
              :to="item.link"
              link
              :key="index"
              active-class="font-weight-bold"
            >
              <v-list-item-title>{{ item.message }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
        <v-list v-else>
          <v-list-item>
            <v-list-item-title>No notifications yet!!</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <div class="justify-center">
        <div v-if="!loading">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text color="grey" v-bind="attrs" v-on="on">
                <v-icon left> mdi-account-cog-outline </v-icon> Account
              </v-btn>
            </template>
            <v-list width="200">
              <v-list-item
                v-for="(item, index) in nav.links"
                :key="index"
                @click.stop="doAction(item.action)"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
                <v-icon right> mdi-{{ item.icon }} </v-icon>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div v-else>
          <v-slide-x-transition>
            <v-progress-circular
              class="mr-9"
              size="30"
              width="3"
              color="primary"
              indeterminate
            />
          </v-slide-x-transition>
        </div>
      </div>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app>
      <v-card elevation="0" style="position: sticky; top: 0px; z-index: 2">
        <v-row justify="center" align="center" class="pt-4">
          <v-avatar size="150">
            <v-img
              contain
              :src="
                user.avatar_url
                  ? user.avatar_url
                  : require('@/assets/download.jpg')
              "
            />
          </v-avatar>
        </v-row>
        <v-row justify="center" align="center">
          <v-col class="text-center pa-0">
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap font-weight-bold">
                    {{ `${user.title_name}` }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    class="text-wrap"
                    v-if="user.employment"
                  >
                    {{ user.employment.staff_position.position }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    class="text-wrap font-weight-medium"
                    v-else
                  >
                    {{ user.corporate_email }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>

        <v-divider />
      </v-card>

      <v-list nav expand class="pt-5">
        <v-list-item-group>
          <template v-for="(item, i) in sidebarLinks">
            <v-list-group
              v-if="item.children && permissions.includes(item.gate)"
              :key="`group-${i}`"
              no-action
              :prepend-icon="item.icon"
              :group="item.route"
              active-class="primary lighten-5 font-weight-bold"
            >
              <template v-slot:activator>
                <v-list-item-title
                  active-class="font-weight-bold"
                  @click="updatePermission(i)"
                >
                  {{ item.title }}
                </v-list-item-title>
              </template>
              <template v-for="(child, i) in item.children">
                <v-list-item
                  v-if="permissions.includes(child.gate)"
                  router
                  :to="`${child.route.name}`"
                  :key="i"
                  link
                  active-class="font-weight-bold"
                >
                  <v-list-item-title v-text="child.title" />
                </v-list-item>
              </template>
            </v-list-group>
            <template v-else>
              <v-list-item
                v-if="!item.children && permissions.includes(item.gate)"
                :key="`item-${i}`"
                link
                router
                :to="item.route"
                active-class="primary lighten-5 font-weight-bold"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="!item.children && !item.gate"
                :key="`item-${i}`"
                link
                router
                :to="`${item.route}`"
                active-class="primary lighten-5 font-weight-bold"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item>
            </template>
          </template>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
  import { ref, reactive, toRefs, watch, getCurrentInstance } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import { uuid } from "vue-uuid";

  export default {
    setup() {
      const vm = getCurrentInstance();
      const {
        user,
        getters_abilities,
        getters_staff_pending_leave,
        getters_staff_notification,
      } = useGetters([
        "user",
        "getters_abilities",
        "getters_staff_pending_leave",
        "getters_staff_notification",
      ]);
      const { signOut, getUserRolePermission, staffPendingLeave } = useActions([
        "signOut",
        "getUserRolePermission",
        "staffPendingLeave",
      ]);

      let color = ref("primary");
      let loading = ref(false);
      let drawer = ref("true");
      let admins = ref([
        ["Management", "mdi-account-multiple-outline"],
        ["Settings", "mdi-cog-outline"],
      ]);
      const nav = reactive({
        links: [
          /* {
                                                                                                                                                                                                                                                                                                      title: "Change Password",
                                                                                                                                                                                                                                                                                                      action: "changePassword",
                                                                                                                                                                                                                                                                                                      icon: "form-textbox-password",
                                                                                                                                                                                                                                                                                                    }, */
          {
            title: "Log Out",
            action: "logOut",
            icon: "exit-to-app",
          },
        ],
      });
      const permissions = ref([]);
      const notifications = ref([]);
      const model = ref(1);

      const sideBar = reactive({
        sidebarLinks: [
          {
            title: "DASHBOARD",
            icon: "mdi-view-dashboard-outline",
            route: "/home",
          },
          {
            title: "LETTERS",
            icon: "mdi-file-document-outline",
            gate: "access_control_management_access",
            route: "document_management",
            children: [
              {
                title: "Document List",
                route: { name: "/document_management/list" },
                gate: "role_access",
              },
            ],
          },
          {
            title: "ACCESS",
            icon: "mdi-account-lock-outline",
            gate: "access_control_management_access",
            route: "access_control_management",
            children: [
              {
                title: "Role",
                route: { name: "/access_control_management/role" },
                gate: "role_access",
              },
              {
                title: "Permission",
                route: { name: "/access_control_management/permission" },
                gate: "permission_access",
              },
              /* {
                                                                                                                                                                                                                                                                                                                        title: "Assign Role",
                                                                                                                                                                                                                                                                                                                        route: { name: "/access_control_management/assignrole" },
                                                                                                                                                                                                                                                                                                                        gate: "assign_role_access",
                                                                                                                                                                                                                                                                                                                      }, */
            ],
          },
          {
            title: "APPRAISAL",
            icon: "mdi-account-multiple-check-outline",
            gate: "staff_appraisal_management_access",
            route: "staff_appraisal_management",
            children: [
              {
                title: "New Appraisal",
                route: { name: "/staff_appraisal_management/appraisal" },
                gate: "new_appraisal_access",
              },
            ],
          },
          {
            title: "COURSE",
            icon: "mdi-notebook-outline",
            gate: "course_management_access",
            route: "course_management",
            children: [
              {
                title: "New Course",
                route: { name: "/course_management/newcourse" },
                gate: "new_course_access",
              },
              {
                title: "Courses",
                route: { name: "/course_management/courses" },
                gate: "available_courses_access",
              },
              {
                title: "Create PCLS",
                route: { name: "/course_management/pcls" },
                gate: "available_courses_access",
              },
              {
                title: "Registration Deadline",
                route: { name: "/course_management/headers" },
                gate: "header_access",
              },
              {
                title: "My Courses",
                route: { name: "/course_management/mycourses" },
                gate: "my_courses_access",
              },
              {
                title: "My Resit Courses",
                route: { name: "/course_management/resit" },
                gate: "my_courses_access",
              },
              {
                title: "My Retake Courses",
                route: { name: "/course_management/retake" },
                gate: "my_courses_access",
              },
              {
                title: "My Course History",
                route: { name: "/course_management/coursehistory" },
                gate: "my_courses_access",
              },
              /* {
                                                                                                                                                                                                                                                                                              title: "Mount Course(s)",
                                                                                                                                                                                                                                                                                              route: { name: "/course_management/mountcourse" },
                                                                                                                                                                                                                                                                                              gate: "mount_courses_access",
                                                                                                                                                                                                                                                                                            }, 
                                                                                                                                                                                                                                                                                        */
              {
                title: "Assign Lecturer(s)",
                route: { name: "/course_management/assigntolecturer" },
                gate: "assign_lecturer_access",
              },
              {
                title: "Broadsheet",
                route: { name: "/course_management/broadsheet" },
                gate: "broadsheet_access",
              },
            ],
          },
          {
            title: "FINANCE",
            icon: "mdi-finance",
            gate: "finance_management_access",
            route: "finance_management",
            children: [
              {
                title: "Enrol Student",
                route: { name: "/finance_management/enrolstudent" },
                gate: "enrol_student_access",
              },
              {
                title: "Late Registration",
                route: { name: "/finance_management/lateregistration" },
                gate: "enrol_student_access",
              },
              {
                title: "Financial Statement",
                route: { name: "/finance_management/financialstatement" },
                gate: "financial_statement_access",
              },
              {
                title: "Enrolled Students",
                route: { name: "/finance_management/enrolledstudent" },
                gate: "enrolled_student_access",
              },
              {
                title: "Miscellaneous",
                route: { name: "/finance_management/miscellaneous" },
                gate: "miscellaneous_fees_access",
              },
              {
                title: "Fees",
                route: { name: "/finance_management/fees" },
                gate: "fees_access",
              },
              {
                title: "Fees List",
                route: { name: "/finance_management/feeslist" },
                gate: "fees_list_access",
              },
              {
                title: "Misc Payment",
                route: { name: "/finance_management/miscpay" },
                gate: "others_fees_access",
              },
            ],
          },
          {
            title: "HOLDS",
            icon: "mdi-account-tie-outline",
            gate: "holds_management_access",
            route: "/holds_management",
            children: [
              {
                title: "Probation",
                route: { name: "/holds_management/probation" },
                gate: "probation_access",
              },
              {
                title: "Uploads",
                route: { name: "/holds_management/uploads" },
                gate: "header_access",
              },
              {
                title: "Broadsheet",
                route: { name: "/holds_management/broadsheet" },
                gate: "header_access",
              },
            ],
          },
          {
            title: "LEAVE",
            icon: "mdi-account-clock-outline",
            gate: "leave_management_access",
            route: "leave_management",
            children: [
              {
                title: "Leave Setup",
                route: { name: "/leave_management/setup" },
                gate: "leave_setup_access",
              },
              {
                title: "Summary",
                route: { name: "/leave_management/summary" },
                gate: "leave_summary_access",
              },
              {
                title: "Leave Approval",
                route: { name: "/leave_management/leaveapproval" },
                gate: "leave_approval_access",
              },
            ],
          },
          {
            title: "LOGS",
            icon: "mdi-file-cabinet",
            gate: "logs_management_access",
            route: "logs_management",
            children: [
              {
                title: "Script",
                route: { name: "/logs_management/scriptlog" },
                gate: "logs_management_access",
              },
              {
                title: "User",
                route: { name: "/logs_management/userlog" },
                gate: "logs_management_access",
              },
            ],
          },
          {
            title: "QUALITY ASSURANCE",
            icon: "mdi-equalizer-outline",
            gate: "access_control_management_access",
            route: "quality_assurance_management",
            children: [
              {
                title: "Overview",
                route: { name: "/quality_assurance_management/overview" },
                gate: "quality_assurance_overview_access",
              },
              {
                title: "Questions",
                route: { name: "/quality_assurance_management/questions" },
                gate: "evaluation_question_access",
              },
              {
                title: "Assessment",
                route: { name: "/quality_assurance_management/assessment" },
                gate: "assessment_access",
              },
            ],
          },
          {
            title: "PROGRAM",
            icon: "mdi-file-document-multiple-outline",
            gate: "program_management_access",
            route: "program_management",
            children: [
              {
                title: "New Program",
                route: { name: "/program_management/newprogram" },
                gate: "new_program_access",
              },
              {
                title: "Program List",
                route: { name: "/program_management/programlist" },
                gate: "program_list_access",
              },
            ],
          },
          {
            title: "SETTINGS",
            icon: "mdi-cog-outline",
            route: "/setup",
            gate: "setup_management_access",
          },
          /* {
                                                                                                                                                                                                                                                                                                                    title: "FACULTY",
                                                                                                                                                                                                                                                                                                                    icon: "mdi-home-group",
                                                                                                                                                                                                                                                                                                                    gate: "faculty_management_access",
                                                                                                                                                                                                                                                                                                                    route: "faculty_management",
                                                                                                                                                                                                                                                                                                                    children: [
                                                                                                                                                                                                                                                                                                                      {
                                                                                                                                                                                                                                                                                                                        title: "New Faculty",
                                                                                                                                                                                                                                                                                                                        route: { name: "/faculty_management/new_faculty" },
                                                                                                                                                                                                                                                                                                                        gate: "new_faculty_access",
                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                      {
                                                                                                                                                                                                                                                                                                                        title: "Faculty List",
                                                                                                                                                                                                                                                                                                                        route: { name: "/faculty_management/faculty_list" },
                                                                                                                                                                                                                                                                                                                        gate: "faculty_list_access",
                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                    ],
                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                  {
                                                                                                                                                                                                                                                                                                                    title: "DEPARTMENT",
                                                                                                                                                                                                                                                                                                                    icon: "mdi-home",
                                                                                                                                                                                                                                                                                                                    gate: "department_management_access",
                                                                                                                                                                                                                                                                                                                    route: "department_management",

                                                                                                                                                                                                                                                                                                                    children: [
                                                                                                                                                                                                                                                                                                                      {
                                                                                                                                                                                                                                                                                                                        title: "New Department",
                                                                                                                                                                                                                                                                                                                        route: { name: "/department_management/newdepartment" },
                                                                                                                                                                                                                                                                                                                        gate: "new_department_access",
                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                      {
                                                                                                                                                                                                                                                                                                                        title: "Department List",
                                                                                                                                                                                                                                                                                                                        route: { name: "/department_management/departmentlist" },
                                                                                                                                                                                                                                                                                                                        gate: "department_list_access",
                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                    ],
                                                                                                                                                                                                                                                                                                                  }, */

          {
            title: "STUDENT ",
            icon: "mdi-school-outline",
            gate: "student_management_access",
            route: "student_management",
            children: [
              {
                title: "Add Student",
                route: { name: "/student_management/addstudent" },
                gate: "add_student_access",
              },
              {
                title: "Search Student",
                route: { name: "/student_management/searchstudent" },
                gate: "search_student_access",
              },
              {
                title: "Result Slip",
                route: { name: "/student_management/resultslip" },
                gate: "result_slip_access",
              },
              {
                title: "Transcript",
                route: { name: "/student_management/transcript" },
                gate: "result_slip_access",
              },
              {
                title: "Student Status",
                route: { name: "/student_management/studentstatus" },
                gate: "student_status_access",
              },
              {
                title: "Student Promotion",
                route: { name: "/student_management/promotion" },
                gate: "student_promotion_access",
              },
            ],
          },

          {
            title: "STAFF",
            icon: "mdi-account-group-outline",
            gate: "staff_management_access",
            route: "staff_management",
            children: [
              {
                title: "Staff List",
                route: { name: "/staff_management/stafflist" },
                gate: "staff_list_access",
              },
            ],
          },
        ],
      });

      const doAction = (name) => {
        switch (name) {
          case "changePassword":
            vm.proxy.$router.replace({
              name: "Password",
            });
            break;

          default:
            signOutAction();
            break;
        }
      };

      const signOutAction = async () => {
        loading.value = true;
        await signOut().then(() => {
          vm.proxy.$router.replace({
            name: "Login",
          });
          loading.value = false;
        });
      };

      watch(
        () => vm.proxy.$route,
        () => {
          getUserRolePermission().finally(() => {
            permissions.value = getters_abilities.value;
            if (!permissions.value.includes("user_access")) {
              signOutAction();
            }
          });

          staffPendingLeave({ status: "Pending" }).finally(() => {
            const count = getters_staff_pending_leave.value.length;
            if (count) notifications.value = getters_staff_notification.value;
          });
        }
      );

      const updatePermission = (i) => {
        if (i) {
          getUserRolePermission().then(
            () => (permissions.value = getters_abilities.value)
          );
        }
      };

      return {
        ...toRefs(sideBar),
        admins,
        user,
        nav,
        loading,
        color,
        drawer,
        doAction,
        signOut,
        signOutAction,
        getters_abilities,
        permissions,
        updatePermission,
        model,
        uuid,
        getters_staff_pending_leave,
        notifications,
        getters_staff_notification,
      };
    },
  };
</script>

<style>
  #avatar:hover {
    cursor: pointer;
  }
  .bg-active {
    background-color: rgba(0, 46, 105, 0) !important;
    color: whitesmoke !important;
  }

  .border {
    background-color: #ffc601 !important;
    color: #002e69 !important;
  }

  .v-application .primary--text {
    color: #002e69 !important;
  }

  @keyframes tilt-shaking {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(5deg);
    }
    50% {
      transform: rotate(0eg);
    }
    75% {
      transform: rotate(-5deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  .constant-tilt-shake {
    animation: tilt-shaking 0.3s infinite;
  }
</style>
